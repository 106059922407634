import React, { Component } from "react";
import { Styled } from "./styled";

class TermSearch extends Component {
  constructor(props) {
    super(props);
    this.bound = {
      searchTerm: () => {
        const term = this.elms.inputRef.value;
        this.setState({ term });
        this.signalChange(term);
      }
    };
    this.setRef = k => d => {
      if (!this.elms) {
        this.elms = {};
      }
      this.elms[k] = d;
    };
    this.setInputRef = this.setRef("inputRef");
    this.state = { searched: undefined };
  }
  signalChange(term) {
    const { whenChange } = this.props;
    if (typeof whenChange === "function") {
      whenChange(term);
    }
  }
  render() {
    const { placeholder } = this.props;
    const { searchTerm } = this.bound;
    return (
      <Styled>
        <p>{prompt}</p>
        <input ref={this.setInputRef} placeholder={placeholder} />
        <button onClick={searchTerm}>Search</button>
      </Styled>
    );
  }
}

export default TermSearch;
