import React, { Component } from "react";
import { Styled, Text } from "./styled";
import { HBox, HSpacer } from "@iml/design-layout";
import step1 from "./etc/step-1.png";
import step2 from "./etc/step-2.png";
import step3 from "./etc/step-3.svg";

const Step = props => {
  const { side, pix, title, children } = props;
  const text = (
    <Text>
      <h1>{title}</h1>
      <div>{children}</div>
    </Text>
  );
  if (side === "left") {
    return (
      <HBox>
        <img src={pix} alt={title} />
        <HSpacer size={280} />
        {text}
      </HBox>
    );
  } else {
    return (
      <HBox>
        {text}
        <HSpacer size={280} />
        <img src={pix} alt={title} />
      </HBox>
    );
  }
};

const BenefitsSection = () => {
  return (
    <Styled>
      <Step
        pix={step1}
        title="One tool used to speak the same language"
        side="left"
      >
        SAAKOR uses skills as a common language between people looking for work,
        work looking for people, and educational programs looking to connect
        people to the labor market.
      </Step>
      <Step
        pix={step2}
        title="Skill requirements validated by professionals"
        side="right"
      >
        Our occupation and skill descriptions are reviewed on a quarterly basis
        by experts on the field to guarantee their accuracy and provide a
        real-time view of the skill demand.
      </Step>
      <Step
        pix={step3}
        title="Training resources adapted to all learners"
        side="left"
      >
        We have aggregated various types of training resources to increase
        access to learning opportunities and ensure everyone finds the training
        that fit with their learning style.
      </Step>
    </Styled>
  );
};

export default BenefitsSection;
