import { regex as regexroute, exec as execroute } from "./route-to-regex";

const clearSlashes = path => {
  return path
    .toString()
    .replace(/\/$/, "")
    .replace(/^\//, "");
};

/**
 * Sanitize options and add default values
 *
 * @param {object} options
 * @returns {object}
 * @private
 */
const cleanRouterOptions = function(options = {}) {
  var defaults = {
    routes: [],
    mode: "history",
    root: "/",
    hooks: {
      before: function() {},
      secure: function() {
        return true;
      }
    },
    page404: function(page) {
      console.error({
        page: page,
        message: "404. Page not found"
      });
    }
  };
  let settings = [
    "routes",
    "mode",
    "root",
    "page404",
    "whenRouteChange"
  ].reduce((acc, key) => {
    acc[key] = options[key] || defaults[key];
    return acc;
  }, {});
  settings.hooks = Object.assign({}, defaults.hooks, options.hooks || {});
  settings.mode = window.history.pushState ? "history" : "hash";
  return settings;
};

const removeMatchingItems = (routes, filter) => {
  const clone = [...routes];
  for (let i = 0, ni = clone.length; i < ni; i += 1) {
    if (filter(clone[i])) {
      clone.slice(i, 1);
      return this;
    }
  }
  return clone;
};

const extractHistoryFragment = ({ root, location = window.location }) => {
  let fragment = decodeURI(location.pathname + location.search);
  fragment = fragment.replace(/\?(.*)$/, "");
  fragment = root !== "/" ? fragment.replace(root, "") : fragment;
  return fragment;
};

const extractHashFragment = ({ root, location = window.location }) => {
  const m = location.href.match(/#(.*)$/);
  return m ? m[1] : "";
};

const navigateHistory = ({ root, path, history = window.history }) => {
  history.pushState({ page: 1 }, "bc", root + clearSlashes(path));
};

const navigateHash = ({ root, path, location = window.location }) => {
  location.href = `${location.href.replace(/#(.*)$/, "")}#${path}`;
};

const findFirstMatchingRoute = (routes, fragment) => {
  let route;
  // some will exit after a match is found
  routes.some(d => {
    const { regex } = d;
    const m = regex.pattern.test(fragment);
    if (m) {
      route = d;
    }
    return m;
  });
  return route;
};

const addRouteRegex = d => {
  const { pattern } = d;
  return { ...d, regex: regexroute(pattern) };
};

/** ########################################
 *      Router
    ######################################## */
class Router {
  constructor(props) {
    this.state = cleanRouterOptions(props);
    window.addEventListener("popstate", e => {
      // onpopstate is not triggered when pushState or replacestate is called
      this.checkFragment();
    });
    this.checkFragment();
  }

  setState(diff) {
    this.state = { ...this.state, ...diff };
  }

  add(path, cb) {
    const { routes } = this.state;
    this.setState({ routes: routes.push({ path, cb }) });
    return this;
  }

  remove(path) {
    const { routes } = this.state;
    this.setState({
      routes: removeMatchingItems(routes, d => d.path === path)
    });
    return this;
  }

  flush() {
    this.setState({ routes: [] });
    return this;
  }

  navigate(path = "") {
    const { root, mode } = this.state;
    if (mode === "history") {
      navigateHistory({ root, path, history: window.history });
    } else {
      navigateHash({ root, path, location: window.location });
    }
    this.checkFragment();
    return this;
  }

  checkFragment() {
    const { root, mode, routes, active } = this.state;
    let getFragment =
      mode === "history" ? extractHistoryFragment : extractHashFragment;
    const fragment = getFragment({ root, location: window.location });
    const newActive = active === fragment ? undefined : fragment;
    if (newActive) {
      const rroutes = routes.map(addRouteRegex);
      const route = findFirstMatchingRoute(rroutes, fragment);
      if (route) {
        const props = execroute(fragment, route.regex);
        this.setState({ active: newActive, data: props });
        this.signalRouteChange(route, props);
      }
    }
  }

  signalRouteChange(route, props) {
    const { whenRouteChange } = this.state;
    if (typeof whenRouteChange === "function") {
      const { pattern, passOn } = route;
      whenRouteChange(pattern, passOn, props);
    }
  }
}

export default Router;
