import styled from "styled-components";

export const Styled = styled.div`
  text-align: center;
`;

export const Columns = styled.div`
  display: flex;
  align-items: top;
  justify-content: center;
`;

export const CardStyled = styled.div`
  width: 270px;
  margin: 0 32px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 2.1;
  text-align: center;

  h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }
`;
