export const table = [
  {
    "slug": "abap",
    "i18n_fr": "ABAP",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in ABAP.",
    "tasks": [
      "recd3GoHu67Un5MCV",
      "recn7tjZNcB5hWA3R",
      "reciGRemUY6h8JQuu",
      "recrhDzBZbcGBxtGs"
    ],
    "_id": "recGtJbHSOEXpVXoV"
  },
  {
    "slug": "adaptability",
    "i18n_fr": "adaptability",
    "Description": "Change approach to situations based on unexpected and sudden changes in people\\'s needs and mood or in trend.",
    "tasks": [
      "recSYRbViANMBLi9l"
    ],
    "_id": "recV0oloTZet7ngTS"
  },
  {
    "slug": "problem_solving",
    "i18n_fr": "problem solving",
    "Description": "Identify the strengths and weaknesses of various abstract rational concepts such as issues opinions and approaches related to a specific problematic situation in order to formulate solutions and alternative methods of tackling the situation.",
    "_id": "recRAipnUTgzqDQET"
  },
  {
    "slug": "agile_software_development",
    "i18n_fr": "Agile software development",
    "Description": "The agile development model is a methodology to design software systems and applications.",
    "tasks": [
      "recBmv2a9FTiKKmqB",
      "rec3lk8cU6hPvbCUC"
    ],
    "_id": "recQZLP0s7PKorB48"
  },
  {
    "slug": "ajax",
    "i18n_fr": "AJAX",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in AJAX.",
    "_id": "recCuLRnRwff00bB6"
  },
  {
    "slug": "ansible",
    "i18n_fr": "Ansible",
    "Description": "The tool Ansible is a software program to perform configuration identification control status accounting and audit.",
    "tasks": [
      "recTLkVqV6WQefaMi"
    ],
    "_id": "rec7jyxS52xN0IR8d"
  },
  {
    "slug": "asp_dotnet",
    "i18n_fr": "ASP.NET",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in ASP.NET.",
    "tasks": [
      "recd3GoHu67Un5MCV"
    ],
    "_id": "recYns0OKCQY3r7sa"
  },
  {
    "slug": "assembly",
    "i18n_fr": "Assembly",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in Assembly.",
    "tasks": [
      "recQp6cNZnOpGNXPr"
    ],
    "_id": "rec8N58OVZcKK0zY4"
  },
  {
    "slug": "csharp",
    "i18n_fr": "C#",
    "Description": "C# is a general-purpose multi-paradigm programming language encompassing static typing strong typing lexically scoped imperative declarative functional generic object-oriented (class-based and component-oriented programming disciplines.",
    "tasks": [
      "rec9kkPLQNk6A3UuC",
      "rec9W6amc0X0xwO0S",
      "recxZ0ciHmxroa0sE",
      "rec2F9rihT3VAelPM"
    ],
    "_id": "recbV8VzMuYGUAKP4"
  },
  {
    "slug": "cpp",
    "i18n_fr": "C++",
    "Description": "C++ is a general-purpose programming language created by Bjarne Stroustrup as an extension of the C programming language or \\\"C with Classes\\\". The language has expanded significantly over time and modern C++ now has object-oriented generic and functional features in addition to facilities for low-level memory manipulation.",
    "tasks": [
      "reclmqEtcnM3Kbw1h",
      "rec9W6amc0X0xwO0S",
      "recxZ0ciHmxroa0sE",
      "rec2F9rihT3VAelPM"
    ],
    "_id": "recXDdWk2JBnA27iQ"
  },
  {
    "slug": "ca_datacom_db",
    "i18n_fr": "CA Datacom/DB",
    "Description": "The computer program CA Datacom/DB is a tool for creating updating and managing databases currently developed by the software company CA Technologies.",
    "tasks": [
      "recoS90Oh3LvLbyNo",
      "reczmpx9of9KIBiDU",
      "rec1OwuwM1gV01k6G"
    ],
    "_id": "recsqBVMrb4Snr9dw"
  },
  {
    "slug": "cassandra",
    "i18n_fr": "Cassandra",
    "Description": "Apache Cassandra is a free and open-source distributed wide column store NoSQL database management system designed to handle large amounts of data across many commodity servers providing high availability with no single point of failure.",
    "tasks": [
      "rec74bQ6d9DVtOZnB",
      "recoS90Oh3LvLbyNo",
      "reczmpx9of9KIBiDU",
      "recYq184kkjecTBUC",
      "recuPXsyC9NT0Dncx",
      "recjFqvpEirvyjI5G"
    ],
    "_id": "recqMObK3MYN5mwcK"
  },
  {
    "slug": "cisco",
    "i18n_fr": "Cisco",
    "Description": "The products available from the network equipment provider Cisco and methods for selecting and procuring the equipment.",
    "_id": "rec8l99g1WQ0Tf160"
  },
  {
    "slug": "cobol",
    "i18n_fr": "COBOL",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in COBOL.",
    "tasks": [
      "rec15mA9yjHa9ky61",
      "recp490NQ1i39OIsT"
    ],
    "_id": "recwkUKyfJ4COuLsF"
  },
  {
    "slug": "coffee_script",
    "i18n_fr": "CoffeeScript",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in CoffeeScript.",
    "tasks": [
      "recpymrM3euHuo8eY"
    ],
    "_id": "recjzwzgm5dAIIzpD"
  },
  {
    "slug": "common_lisp",
    "i18n_fr": "Common Lisp",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in Common Lisp.",
    "tasks": [
      "recinnuSKAv8Cnm8Q"
    ],
    "_id": "recpfMg61Q3XVIPll"
  },
  {
    "slug": "dax",
    "i18n_fr": "DAX",
    "Description": "Data Analysis Expressions (DAX) is the native formula and query language for Microsoft PowerPivot Power BI Desktop and SQL Server Analysis Services (SSAS) Tabular models.",
    "tasks": [
      "reciGRemUY6h8JQuu",
      "recrhDzBZbcGBxtGs",
      "recXzypfwTcLObGFR"
    ],
    "_id": "reczddTxBDeCf0L7k"
  },
  {
    "slug": "db_2",
    "i18n_fr": "DB2",
    "Description": "The computer program IBM DB2 is a tool for creating updating and managing databases developed by the software company IBM.",
    "tasks": [
      "recoS90Oh3LvLbyNo",
      "reczmpx9of9KIBiDU",
      "rec1OwuwM1gV01k6G"
    ],
    "_id": "recOVtGPOoeIbUfXZ"
  },
  {
    "slug": "eclipse",
    "i18n_fr": "Eclipse",
    "Description": "The computer program Eclipse is a suite of software development tools for writing programs such as compiler debugger code editor code highlights packaged in a unified user interface. It is developed by the Eclipse Foundation.",
    "tasks": [
      "rec9kkPLQNk6A3UuC",
      "reclmqEtcnM3Kbw1h",
      "recO7DzlpuG6sVqxF",
      "recvCo4AX1Sb9FU8F",
      "recEgCpGeGLFJWTsV",
      "rec3lk8cU6hPvbCUC",
      "recrSgWc9kJ9H20Iv"
    ],
    "_id": "recd4Wxlzb5RDJHpj"
  },
  {
    "slug": "erlang",
    "i18n_fr": "Erlang",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in Erlang.",
    "_id": "recaLNlUnX29swNRV"
  },
  {
    "slug": "filemaker",
    "i18n_fr": "Filemaker",
    "Description": "The computer program FileMaker is a tool for creating updating and managing databases developed by the software company FileMaker Inc.",
    "tasks": [
      "recoS90Oh3LvLbyNo",
      "reczmpx9of9KIBiDU"
    ],
    "_id": "recsIwqkQ88suvu5J"
  },
  {
    "slug": "groovy",
    "i18n_fr": "Groovy",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in Groovy.",
    "_id": "recytNbjMyJER0EXk"
  },
  {
    "slug": "hadoop",
    "i18n_fr": "Hadoop",
    "Description": "Apache Hadoop is an open-source Big Data Platform which is the bread and butter for all the data engineers. It comprises of Hadoop Distributed Framework or HDFS which is designed to run on commodity hardware. A Data Engineer must be well versed with Hadoop as it is the standard Big Data platform for many industries.",
    "tasks": [
      "recjFqvpEirvyjI5G",
      "recl95MWv7qUvcfV5"
    ],
    "_id": "recOdCreCZbtU73QL"
  },
  {
    "slug": "haskell",
    "i18n_fr": "Haskell",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in Haskell.",
    "tasks": [
      "recpymrM3euHuo8eY"
    ],
    "_id": "recwsso7iGQaexMFP"
  },
  {
    "slug": "hdfs",
    "i18n_fr": "HDFS",
    "Description": "The Hadoop Distributed File System (HDFS) is a distributed file system designed to run on commodity hardware.",
    "tasks": [
      "recQp6cNZnOpGNXPr"
    ],
    "_id": "recLnmJEMGdSE6xEo"
  },
  {
    "slug": "ibm_informix",
    "i18n_fr": "IBM Informix",
    "Description": "The computer program IBM Informix is a tool for creating updating and managing databases developed by the software company IBM.",
    "tasks": [
      "recoS90Oh3LvLbyNo",
      "reczmpx9of9KIBiDU",
      "rec1OwuwM1gV01k6G"
    ],
    "_id": "recsSSRo8lfyzHoBx"
  },
  {
    "slug": "ibm_info_sphere_data_stage",
    "i18n_fr": "IBM InfoSphere DataStage",
    "Description": "The computer program IBM InfoSphere DataStage is a tool for integration of information from multiple applications created and maintained by organisations into one consistent and transparent data structure developed by the software company IBM.",
    "_id": "reczLQgCAIMXmh3oe"
  },
  {
    "slug": "ibm_info_sphere_information_server",
    "i18n_fr": "IBM InfoSphere Information Server",
    "Description": "The software program IBM InfoSphere Information Server is a platform for integration of information from multiple applications created and maintained by organisations into one consistent and transparent data structure developed by the software company IBM.",
    "tasks": [
      "recrhDzBZbcGBxtGs",
      "recXljT6tl3ymvxHS"
    ],
    "_id": "recNKlUrhi2lC3zbL"
  },
  {
    "slug": "informatica_power_center",
    "i18n_fr": "Informatica PowerCenter",
    "Description": "The computer program Informatica PowerCenter is a tool for integration of information from multiple applications created and maintained by organisations into one consistent and transparent data structure developed by the software company Informatica.",
    "tasks": [
      "recAXTCXPAEYiAM7u",
      "recQp6cNZnOpGNXPr",
      "recqnDjjIvdfmiqf8"
    ],
    "_id": "recu7ml1BE16Cv4b0"
  },
  {
    "slug": "integrated_development_environment_software",
    "i18n_fr": "integrated development environment software",
    "Description": "The suite of software development tools for writing programs such as compiler debugger code editor code highlights packaged in a unified user interface such as Visual Studio or Eclipse.",
    "_id": "recH5XdCB62Uiqluz"
  },
  {
    "slug": "java",
    "i18n_fr": "Java",
    "Description": "Java is a class-based object-oriented programming language that is designed to have as few implementation dependencies as possible. It is a general-purpose programming language intended to let application developers write once run anywhere meaning that compiled Java code can run on all platforms that support Java without the need for recompilation.",
    "tasks": [
      "rec9kkPLQNk6A3UuC",
      "reclmqEtcnM3Kbw1h",
      "rec9W6amc0X0xwO0S",
      "receFvIZIE5mjeVt8",
      "recxZ0ciHmxroa0sE",
      "recrSgWc9kJ9H20Iv",
      "rec2F9rihT3VAelPM"
    ],
    "_id": "reczX30nq1YWssKAi"
  },
  {
    "slug": "java_script",
    "i18n_fr": "JavaScript",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in JavaScript.",
    "tasks": [
      "receFvIZIE5mjeVt8",
      "recxZ0ciHmxroa0sE",
      "recpymrM3euHuo8eY"
    ],
    "_id": "recSMuzLyLMAF3tOx"
  },
  {
    "slug": "jenkins",
    "i18n_fr": "Jenkins",
    "Description": "The tool Jenkins is a software program to perform configuration identification control status accounting and audit of software during its development and maintenance.",
    "tasks": [
      "recL4sF7mQp38frm2"
    ],
    "_id": "recDszx6G5kxpEg5L"
  },
  {
    "slug": "kdevelop",
    "i18n_fr": "KDevelop",
    "Description": "The computer program KDevelop is a suite of software development tools for writing programs such as compiler debugger code editor code highlights packaged in a unified user interface. It is developed by the software community KDE.",
    "tasks": [
      "recuiCHr9ARNxfQvO"
    ],
    "_id": "recQjruVmF6TyGCF2"
  },
  {
    "slug": "ldap",
    "i18n_fr": "LDAP",
    "Description": "The computer language LDAP is a query language for retrieval of information from a database and of documents containing the needed information.",
    "_id": "recOElgKWJ0RKZ72y"
  },
  {
    "slug": "lean_project_management",
    "i18n_fr": "Lean project management",
    "Description": "The lean project management approach is a methodology for planning managing and overseeing of ICT resources in order to meet specific goals and using project management ICT tools.",
    "tasks": [
      "recBmv2a9FTiKKmqB"
    ],
    "_id": "rec6ng7sXvEhl20X9"
  },
  {
    "slug": "linq",
    "i18n_fr": "LINQ",
    "Description": "The computer language LINQ is a query language for retrieval of information from a database and of documents containing the needed information. It is developed by the software company Microsoft.",
    "tasks": [
      "recrhDzBZbcGBxtGs",
      "recXzypfwTcLObGFR"
    ],
    "_id": "recCWbExpgZ8orDOx"
  },
  {
    "slug": "lisp",
    "i18n_fr": "Lisp",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in Lisp.",
    "tasks": [
      "recinnuSKAv8Cnm8Q"
    ],
    "_id": "recwA7GEZYXgDilV6"
  },
  {
    "slug": "matlab",
    "i18n_fr": "MATLAB",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in MATLAB.",
    "tasks": [
      "recd3GoHu67Un5MCV",
      "reciGRemUY6h8JQuu",
      "reclrFRctY9VnDkxV",
      "receTkjf9nDeIftj4"
    ],
    "_id": "reccetSZBvLlbFTRh"
  },
  {
    "slug": "mdx",
    "i18n_fr": "MDX",
    "Description": "The computer language MDX is a query language for retrieval of information from a database and of documents containing the needed information. It is developed by the software company Microsoft.",
    "tasks": [
      "recrhDzBZbcGBxtGs",
      "recXzypfwTcLObGFR"
    ],
    "_id": "recTlIjLd2vAHQRC3"
  },
  {
    "slug": "ml_programming_language",
    "i18n_fr": "ML programming language",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in ML.",
    "_id": "recy8sSA6gho2v7xq"
  },
  {
    "slug": "microsoft_access",
    "i18n_fr": "Microsoft Access",
    "Description": "The computer program Access is a tool for creating updating and managing databases developed by the software company Microsoft.",
    "tasks": [
      "recoS90Oh3LvLbyNo",
      "reczmpx9of9KIBiDU"
    ],
    "_id": "recHJRk6SBCH2Q96U"
  },
  {
    "slug": "microsoft_visual_cpp",
    "i18n_fr": "Microsoft Visual C++",
    "Description": "The computer program Visual C++ is a suite of software development tools for writing programs such as compiler debugger code editor code highlights packaged in a unified user interface. It is developed by the software company Microsoft.",
    "_id": "recni6eQQ1FoarvJJ"
  },
  {
    "slug": "mongo_db",
    "i18n_fr": "Mongo DB",
    "Description": "MongoDB is an open source NoSQL database management program. It is a tool that can manage document-oriented information store or retrieve information.",
    "tasks": [
      "rec74bQ6d9DVtOZnB",
      "recoS90Oh3LvLbyNo",
      "reczmpx9of9KIBiDU",
      "recYq184kkjecTBUC",
      "recuPXsyC9NT0Dncx",
      "recjFqvpEirvyjI5G"
    ],
    "_id": "recUhjzuWrlGHR00Y"
  },
  {
    "slug": "my_sql",
    "i18n_fr": "MySQL",
    "Description": "The computer program MySQL is a tool for creating updating and managing databases currently developed by the software company Oracle.",
    "tasks": [
      "recd3GoHu67Un5MCV",
      "reclrFRctY9VnDkxV",
      "recXzypfwTcLObGFR"
    ],
    "_id": "recjDYugUQsZSIQ8g"
  },
  {
    "slug": "n_1_ql",
    "i18n_fr": "N1QL",
    "Description": "The computer language N1QL is a query language for retrieval of information from a database and of documents containing the needed information. It is developed by the software company Couchbase.",
    "tasks": [
      "recrhDzBZbcGBxtGs",
      "recXzypfwTcLObGFR"
    ],
    "_id": "reczdev3pnh4mnzYS"
  },
  {
    "slug": "objective_c",
    "i18n_fr": "Objective-C",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in Objective-C.",
    "_id": "recm05mOGPZ71qYj0"
  },
  {
    "slug": "object_store",
    "i18n_fr": "ObjectStore",
    "Description": "The computer program ObjectStore is a tool for creating updating and managing databases developed by the software company Object Design Incorporated.",
    "tasks": [
      "rec74bQ6d9DVtOZnB",
      "recoS90Oh3LvLbyNo",
      "reczmpx9of9KIBiDU",
      "recYq184kkjecTBUC",
      "recjFqvpEirvyjI5G"
    ],
    "_id": "recfTg9R9lk4pH1nS"
  },
  {
    "slug": "open_edge_advanced_business_language",
    "i18n_fr": "OpenEdge Advanced Business Language",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in OpenEdge Advanced Business Language.",
    "_id": "rec4hQQkRVJczNJd7"
  },
  {
    "slug": "open_edge_database",
    "i18n_fr": "OpenEdge Database",
    "Description": "The computer program OpenEdge Database is a tool for creating updating and managing databases developed by the software company Progress Software Corporation.",
    "_id": "recZ86tKvswWoTQ9H"
  },
  {
    "slug": "oracle_data_integrator",
    "i18n_fr": "Oracle Data Integrator",
    "Description": "The computer program Oracle Data Integrator is a tool for integration of information from multiple applications created and maintained by organisations into one consistent and transparent data structure developed by the software company Oracle.",
    "tasks": [
      "reclPherlv8HvDolr",
      "recjnWPCBaKAPaoxr",
      "recvCo4AX1Sb9FU8F",
      "reczrWbVHTZDLKpcn",
      "recoS90Oh3LvLbyNo",
      "reczmpx9of9KIBiDU",
      "rec1OwuwM1gV01k6G",
      "reca0wci8sSQXmx7M",
      "recXljT6tl3ymvxHS"
    ],
    "_id": "rec0JLL5Okxt5iVts"
  },
  {
    "slug": "oracle_warehouse_builder",
    "i18n_fr": "Oracle Warehouse Builder",
    "Description": "The computer program Oracle Warehouse Builder is a tool for integration of information from multiple applications created and maintained by organisations into one consistent and transparent data structure developed by the software company Oracle.",
    "tasks": [
      "recw5oesT6KxEPqlP",
      "rec74bQ6d9DVtOZnB",
      "rec8d7OVyPYNkPhZS"
    ],
    "_id": "recxu1vcyj3AcS13q"
  },
  {
    "slug": "pascal",
    "i18n_fr": "Pascal",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in Pascal.",
    "_id": "recrJwPqQGMOu9wMp"
  },
  {
    "slug": "pentaho_data_integration",
    "i18n_fr": "Pentaho Data Integration",
    "Description": "The computer program Pentaho Data Integration is a tool for integration of information from multiple applications created and maintained by organisations into one consistent and transparent data structure developed by the software company Pentaho.",
    "tasks": [
      "recAXTCXPAEYiAM7u",
      "recjnWPCBaKAPaoxr",
      "reczrWbVHTZDLKpcn"
    ],
    "_id": "recwhZYVGpsDG4ega"
  },
  {
    "slug": "perl",
    "i18n_fr": "Perl",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in Perl.",
    "tasks": [
      "recQp6cNZnOpGNXPr",
      "reccpJl4kljvhFH26"
    ],
    "_id": "recM4enxPhCLTmBu9"
  },
  {
    "slug": "php",
    "i18n_fr": "PHP",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in PHP.",
    "_id": "rec4u9uTc4aGrwbNl"
  },
  {
    "slug": "postgre_sql",
    "i18n_fr": "PostgreSQL",
    "Description": "The computer program PostgreSQL is a free and open-source software tool for creating updating and managing databases developed by the PostgreSQL Global Development Group.",
    "tasks": [
      "recd3GoHu67Un5MCV",
      "reclrFRctY9VnDkxV",
      "recXzypfwTcLObGFR"
    ],
    "_id": "rec2UUQfh04OjdVI3"
  },
  {
    "slug": "power_bi",
    "i18n_fr": "Power BI",
    "Description": "Power BI is a business analytics service by Microsoft. It aims to provide interactive visualizations and business intelligence capabilities with an interface simple enough for end users to create their own reports and dashboards.",
    "tasks": [
      "reci5TT0796QDAnmt",
      "recn7tjZNcB5hWA3R",
      "rec7gzIMNWNvfM2Vf",
      "recfHEURDctM5SBJ5",
      "recoS90Oh3LvLbyNo",
      "reczmpx9of9KIBiDU",
      "rec1OwuwM1gV01k6G",
      "reca0wci8sSQXmx7M",
      "recXljT6tl3ymvxHS",
      "recQqe8MmkzujAVEn"
    ],
    "_id": "rec5a2g099tK2LLu2"
  },
  {
    "slug": "process_based_management",
    "i18n_fr": "Process-based management",
    "Description": "The process-based management approach is a methodology for planning managing and overseeing of ICT resources in order to meet specific goals and using project management ICT tools.",
    "tasks": [
      "recBmv2a9FTiKKmqB"
    ],
    "_id": "recnVeb4xetU5E92h"
  },
  {
    "slug": "prolog",
    "i18n_fr": "Prolog",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in Prolog.",
    "tasks": [
      "reccpJl4kljvhFH26"
    ],
    "_id": "rec7sHWmVmJSP5bOA"
  },
  {
    "slug": "puppet",
    "i18n_fr": "Puppet",
    "Description": "The tool Puppet is a software program to perform configuration identification control status accounting and audit.",
    "tasks": [
      "reclmqEtcnM3Kbw1h"
    ],
    "_id": "receZy852FFxItsOs"
  },
  {
    "slug": "python",
    "i18n_fr": "Python",
    "Description": "Python is an interpreted high-level and general-purpose programming language. Python design philosophy emphasizes code readability with its notable use of significant indentation. Its language constructs and object-oriented approach aim to help programmers write clear logical code for small and large-scale projects.",
    "tasks": [
      "reczKDpDApvyj4BqJ",
      "reci5TT0796QDAnmt",
      "reclPherlv8HvDolr",
      "recJP0Czoiu38oFyX",
      "reciGRemUY6h8JQuu",
      "rec7gzIMNWNvfM2Vf",
      "reclrFRctY9VnDkxV",
      "recfHEURDctM5SBJ5",
      "receTkjf9nDeIftj4",
      "recXljT6tl3ymvxHS",
      "recGCSatmE4QunfPi",
      "rec9W6amc0X0xwO0S",
      "receFvIZIE5mjeVt8",
      "reccpJl4kljvhFH26",
      "recxZ0ciHmxroa0sE",
      "recinnuSKAv8Cnm8Q",
      "recj60hMGvgCYQbZh",
      "recpymrM3euHuo8eY"
    ],
    "_id": "rec8OZsTp2TeXTRWh"
  },
  {
    "slug": "qlik_view_expressor",
    "i18n_fr": "QlikView Expressor",
    "Description": "The computer program QlikView Expressor is a tool for integration of information from multiple applications created and maintained by organisations into one consistent and transparent data structure developed by the software company Qlik.",
    "tasks": [
      "recn7tjZNcB5hWA3R",
      "recJP0Czoiu38oFyX",
      "recrhDzBZbcGBxtGs",
      "recXzypfwTcLObGFR"
    ],
    "_id": "recHmih2kMDn6vw9Q"
  },
  {
    "slug": "r",
    "i18n_fr": "R",
    "Description": "R is a programming language and free software environment for statistical computing and graphics supported by the R Foundation for Statistical Computing. The R language is widely used among statisticians and data miners for developing statistical software and data analysis.",
    "tasks": [
      "recd3GoHu67Un5MCV",
      "reci5TT0796QDAnmt",
      "reclPherlv8HvDolr",
      "reciGRemUY6h8JQuu",
      "rec7gzIMNWNvfM2Vf",
      "reclrFRctY9VnDkxV",
      "recfHEURDctM5SBJ5",
      "receTkjf9nDeIftj4",
      "recXljT6tl3ymvxHS"
    ],
    "_id": "rec6rG96YBHMQThzW"
  },
  {
    "slug": "ruby",
    "i18n_fr": "Ruby",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in Ruby.",
    "tasks": [
      "recI0yzHcqbV9JILb"
    ],
    "_id": "recaR6hpcQjo5p6X9"
  },
  {
    "slug": "salt",
    "i18n_fr": "Salt",
    "Description": "The tool Salt is a software program to perform configuration identification control status accounting and audit.",
    "tasks": [
      "recQp6cNZnOpGNXPr",
      "recqnDjjIvdfmiqf8",
      "recYq184kkjecTBUC"
    ],
    "_id": "recR7jbBVQF8nOot5"
  },
  {
    "slug": "sap_data_services",
    "i18n_fr": "SAP Data Services",
    "Description": "The computer program SAP Data Services is a tool for integration of information from multiple applications created and maintained by organisations into one consistent and transparent data structure developed by the software company SAP.",
    "tasks": [
      "recAXTCXPAEYiAM7u",
      "recJP0Czoiu38oFyX",
      "recjnWPCBaKAPaoxr",
      "reczrWbVHTZDLKpcn"
    ],
    "_id": "recIhDAhbRiMbroCV"
  },
  {
    "slug": "sap_r_3",
    "i18n_fr": "SAP R3",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in SAP R3.",
    "_id": "recmDvQfIBLMZohZW"
  },
  {
    "slug": "sas",
    "i18n_fr": "SAS",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in SAS language.",
    "tasks": [
      "reci5TT0796QDAnmt",
      "recn7tjZNcB5hWA3R",
      "rec7gzIMNWNvfM2Vf",
      "recfHEURDctM5SBJ5",
      "recrhDzBZbcGBxtGs",
      "recXzypfwTcLObGFR",
      "recoS90Oh3LvLbyNo",
      "reczmpx9of9KIBiDU",
      "rec1OwuwM1gV01k6G",
      "reca0wci8sSQXmx7M",
      "recXljT6tl3ymvxHS",
      "recGCSatmE4QunfPi"
    ],
    "_id": "recRzJtuFIpeGkKj6"
  },
  {
    "slug": "scala",
    "i18n_fr": "Scala",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in Scala.",
    "tasks": [
      "reczKDpDApvyj4BqJ",
      "recGCSatmE4QunfPi",
      "recinnuSKAv8Cnm8Q"
    ],
    "_id": "reczdxXM2nZFsPecJ"
  },
  {
    "slug": "scratch",
    "i18n_fr": "Scratch",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in Scratch.",
    "_id": "recayHjuVIqGxeZjQ"
  },
  {
    "slug": "smalltalk",
    "i18n_fr": "Smalltalk",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in Smalltalk.",
    "tasks": [
      "recuiCHr9ARNxfQvO"
    ],
    "_id": "rec3EMggAFhqgPY8Z"
  },
  {
    "slug": "spark",
    "i18n_fr": "Spark",
    "Description": "Spark is a fast processing analytical big data platform provided by Apache. It was developed as an improvement over Hadoop which could only handle batch data. However Spark provides support for both batch data as well as streaming data.",
    "tasks": [
      "recIuwYpnHqxLKcHh",
      "reczKDpDApvyj4BqJ",
      "recJP0Czoiu38oFyX",
      "recjnWPCBaKAPaoxr",
      "recuPXsyC9NT0Dncx",
      "recGCSatmE4QunfPi",
      "recinnuSKAv8Cnm8Q",
      "recjFqvpEirvyjI5G",
      "recl95MWv7qUvcfV5"
    ],
    "_id": "recD8MIIKPzqwvaZV"
  },
  {
    "slug": "sparql",
    "i18n_fr": "SPARQL",
    "Description": "The computer language SPARQL is a query language for retrieval of information from a database and of documents containing the needed information. It is developed by the international standards organisation World Wide Web Consortium.",
    "tasks": [
      "recXzypfwTcLObGFR"
    ],
    "_id": "recGdxuai6WwvGapL"
  },
  {
    "slug": "sql_server",
    "i18n_fr": "SQL Server",
    "Description": "The computer program SQL Server is a tool for creating updating and managing databases developed by the software company Microsoft.",
    "tasks": [
      "rec8d7OVyPYNkPhZS",
      "rec1OwuwM1gV01k6G",
      "reca0wci8sSQXmx7M"
    ],
    "_id": "recIEvdjdYppBJmou"
  },
  {
    "slug": "sql_server_integration_services",
    "i18n_fr": "SQL Server Integration Services",
    "Description": "The computer program SQL Server Integration Services is a tool for integration of information from multiple applications created and maintained by organisations into one consistent and transparent data structure developed by the software company Microsoft.",
    "tasks": [
      "recw5oesT6KxEPqlP",
      "recjnWPCBaKAPaoxr",
      "recvCo4AX1Sb9FU8F",
      "reczrWbVHTZDLKpcn",
      "recoS90Oh3LvLbyNo",
      "reczmpx9of9KIBiDU"
    ],
    "_id": "recCIRJeMR79RM3d6"
  },
  {
    "slug": "staf",
    "i18n_fr": "STAF",
    "Description": "The tool STAF is a software program to perform configuration identification control status accounting and audit.",
    "tasks": [
      "recEgCpGeGLFJWTsV"
    ],
    "_id": "rec4th2r2PE7blYbv"
  },
  {
    "slug": "swift",
    "i18n_fr": "Swift",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in Swift.",
    "_id": "recVM0Y4jUDjoRQpM"
  },
  {
    "slug": "tableau",
    "i18n_fr": "Tableau",
    "Description": "Tableau Software is an interactive data visualization software company focused on business intelligence.Tableau products query relational databases online analytical processing cubes cloud databases and spreadsheets to generate graph-type data visualizations.",
    "tasks": [
      "reci5TT0796QDAnmt",
      "recn7tjZNcB5hWA3R",
      "recJP0Czoiu38oFyX",
      "rec7gzIMNWNvfM2Vf",
      "recfHEURDctM5SBJ5",
      "recoS90Oh3LvLbyNo",
      "reczmpx9of9KIBiDU",
      "reca0wci8sSQXmx7M",
      "recXljT6tl3ymvxHS",
      "recQqe8MmkzujAVEn"
    ],
    "_id": "recv21KcqVUoRbeWG"
  },
  {
    "slug": "teradata_database",
    "i18n_fr": "Teradata Database",
    "Description": "The computer program Teradata Database is a tool for creating updating and managing databases developed by the software company Teradata Corporation.",
    "tasks": [
      "recw5oesT6KxEPqlP",
      "recjnWPCBaKAPaoxr",
      "rec74bQ6d9DVtOZnB",
      "rec8d7OVyPYNkPhZS",
      "recoS90Oh3LvLbyNo",
      "reczmpx9of9KIBiDU",
      "recjFqvpEirvyjI5G"
    ],
    "_id": "recnws7D6JsFyFQCe"
  },
  {
    "slug": "type_script",
    "i18n_fr": "TypeScript",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in TypeScript.",
    "tasks": [
      "receFvIZIE5mjeVt8",
      "recxZ0ciHmxroa0sE",
      "recjFqvpEirvyjI5G",
      "recpymrM3euHuo8eY"
    ],
    "_id": "recbFCdOxeP8IrFJq"
  },
  {
    "slug": "vagrant",
    "i18n_fr": "Vagrant",
    "Description": "The tool Vagrant is a software program to perform configuration identification control status accounting and audit.",
    "tasks": [
      "recQp6cNZnOpGNXPr"
    ],
    "_id": "recwhwDytIEbxstx2"
  },
  {
    "slug": "vbscript",
    "i18n_fr": "VBScript",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in VBScript.",
    "tasks": [
      "recI0yzHcqbV9JILb",
      "recuiCHr9ARNxfQvO"
    ],
    "_id": "rechqVNmYf8EqDGny"
  },
  {
    "slug": "visual_studio_net",
    "i18n_fr": "Visual Studio.NET",
    "Description": "The techniques and principles of software development such as analysis algorithms coding testing and compiling of programming paradigms in Visual Basic.",
    "tasks": [
      "rec9kkPLQNk6A3UuC",
      "reclmqEtcnM3Kbw1h",
      "recO7DzlpuG6sVqxF",
      "recvCo4AX1Sb9FU8F",
      "recEgCpGeGLFJWTsV",
      "rec3lk8cU6hPvbCUC"
    ],
    "_id": "rec0lfKWs8rdCo6Q6"
  },
  {
    "slug": "xcode",
    "i18n_fr": "Xcode",
    "Description": "The computer program Xcode is a suite of software development tools for writing programs such as compiler debugger code editor code highlights packaged in a unified user interface. It is developed by the software company Apple.",
    "tasks": [
      "rec9kkPLQNk6A3UuC",
      "reclmqEtcnM3Kbw1h",
      "recO7DzlpuG6sVqxF",
      "recvCo4AX1Sb9FU8F",
      "recEgCpGeGLFJWTsV",
      "rec3lk8cU6hPvbCUC"
    ],
    "_id": "recTxrra31W4emm25"
  },
  {
    "slug": "xquery",
    "i18n_fr": "XQuery",
    "Description": "The computer language XQuery is a query language for retrieval of information from a database and of documents containing the needed information. It is developed by the international standards organisation World Wide Web Consortium.",
    "_id": "recybMZDi3Ey5K23U"
  },
  {
    "slug": "yarn_cluster_manager",
    "i18n_fr": "YARN cluster manager",
    "Description": "Yarn is a part of the Hadoop Core project. It allows several data-processing engines to handle data on a single platform. It is an efficient tool to increase the efficiency of the Hadoop compute cluster.",
    "tasks": [
      "recjFqvpEirvyjI5G"
    ],
    "_id": "reciLIUEQ51J9QyIR"
  },
  {
    "slug": "object_oriented_programming",
    "i18n_fr": "Object-oriented programming",
    "tasks": [
      "recSYRbViANMBLi9l",
      "recrSgWc9kJ9H20Iv",
      "recj60hMGvgCYQbZh"
    ],
    "_id": "recOUQENHMVa2E4S5"
  },
  {
    "slug": "intelli_j_idea",
    "i18n_fr": "IntelliJ Idea",
    "Description": "IntelliJ IDEA is an integrated development environment (IDE) written in Java for developing computer software.",
    "tasks": [
      "recrSgWc9kJ9H20Iv",
      "recj60hMGvgCYQbZh"
    ],
    "_id": "recHM3tqCuGaOvoRm"
  },
  {
    "slug": "javac",
    "i18n_fr": "Javac",
    "Description": "The Javac tool reads class and interface definitions written in the Java programming language and compiles them into bytecode class files. It can also process annotations in Java source files and classes.",
    "tasks": [
      "rect8WK1XqiZDjePz"
    ],
    "_id": "rec1m174gN77Sgmqs"
  },
  {
    "slug": "jar",
    "i18n_fr": "Jar",
    "Description": "A JAR (Java ARchive) is a package file format typically used to aggregate many Java class files and associated metadata and resources (text images etc.) into one file for distribution.",
    "tasks": [
      "rec23A4sYpb6azRct"
    ],
    "_id": "recg0daRtcTlXCiMe"
  },
  {
    "slug": "maven",
    "i18n_fr": "Maven",
    "Description": "Maven is a build automation tool used primarily for Java projects. Maven can also be used to build and manage projects written in C# Ruby Scala and other languages. The Maven project is hosted by the Apache Software Foundation where it was formerly part of the Jakarta Project.",
    "tasks": [
      "recBmv2a9FTiKKmqB",
      "rec23A4sYpb6azRct"
    ],
    "_id": "rec6YoQ05JFlminLL"
  },
  {
    "slug": "gradle",
    "i18n_fr": "Gradle",
    "Description": "Gradle is a build automation tool for multi-language software development. It controls the development process in the tasks of compilation and packaging to testing deployment and publishing. Supported languages include Java (Kotlin Groovy Scala C/C++ JavaScript.",
    "tasks": [
      "rec23A4sYpb6azRct"
    ],
    "_id": "recbu9t6j2HN2StU3"
  },
  {
    "slug": "ant",
    "i18n_fr": "Ant",
    "Description": "Apache Ant is a software tool for automating software build processes which originated from the Apache Tomcat project in early 2000 as a replacement for the Make build tool of Unix. It is similar to Make but is implemented using the Java language and requires the Java platform.",
    "tasks": [
      "rec23A4sYpb6azRct"
    ],
    "_id": "recwbzECLIM4E8CQ6"
  },
  {
    "slug": "oo_design_patterns",
    "i18n_fr": "OO design patterns",
    "tasks": [
      "recJCVOTFfc2EjdNS"
    ],
    "_id": "recsEe7kQ41npXawL"
  },
  {
    "slug": "n_tier_architecture",
    "i18n_fr": "N-tier Architecture",
    "Description": "An N-tier architecture divides an application into logical layers and physical tiers. Layers are a way to separate responsibilities and manage dependencies. Each layer has a specific responsibility. A higher layer can use services in a lower layer but not the other way around.",
    "tasks": [
      "recJCVOTFfc2EjdNS"
    ],
    "_id": "recRhzmBGevT954dW"
  },
  {
    "slug": "spring_boot",
    "i18n_fr": "Spring Boot",
    "Description": "Spring Boot is an open source Java-based framework used to create a Micro Service. It is developed by Pivotal Team.",
    "tasks": [
      "rec3NjZeCim6rgoY3"
    ],
    "_id": "rec9YD1Rqc7am0wr7"
  },
  {
    "slug": "jax_rs",
    "i18n_fr": "JAX-RS",
    "Description": "JAX-RS stands for JAVA API for RESTful Web Services. JAX-RS is a JAVA based programming language API and specification to provide support for created RESTful Web Services.",
    "tasks": [
      "rec3NjZeCim6rgoY3"
    ],
    "_id": "recAqk5cjuwemQMl3"
  },
  {
    "slug": "j_2_e",
    "i18n_fr": "J2E",
    "tasks": [
      "reczaQMnOeLu1ZtiF"
    ],
    "_id": "recySiHanmUMHEVlx"
  },
  {
    "slug": "jre",
    "i18n_fr": "JRE",
    "Description": "The Java Runtime Environment or JRE is a software layer that runs on top of a computer’s operating system software and provides the class libraries and other resources that a specific Java program needs to run.",
    "tasks": [
      "reczaQMnOeLu1ZtiF"
    ],
    "_id": "recFffyPmvX5GL85V"
  }
]