import React, { Component, Fragment } from "react";

import Router from "@iml/io/router";

import HomeSection from "@skc/block-skillscloud/home-section";
import SearchSection from "@skc/block-skillscloud/search-section";
import FaqSection from "@skc/block-skillscloud/faq-section";
import ContactSection from "@skc/block-skillscloud/contact-section";

import SkillsExplorer from "@skc/block-skillscloud/skills-explorer";
import RolesExplorer from "@skc/block-skillscloud/roles-explorer";

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = { router: undefined };
  }
  componentDidMount() {
    let router = new Router({
      routes: [
        {
          pattern: "/search",
          passOn: {
            render: props => <SearchSection />
          }
        },
        {
          pattern: "/explore",
          passOn: {
            render: props => <SearchSection />
          }
        },
        {
          pattern: "/contacts",
          passOn: {
            render: props => <ContactSection />
          }
        },
        {
          pattern: "/faq",
          passOn: {
            render: props => <FaqSection />
          }
        },
        {
          pattern: "/skill",
          passOn: {
            render: props => <SkillsExplorer />
          }
        },
        {
          pattern: "/skill/:skillName",
          passOn: {
            render: props => <div>skills {props.skillName}</div>
          }
        },
        {
          pattern: "/role",
          passOn: {
            render: props => <RolesExplorer />
          }
        },
        {
          pattern: "/role/:roleName",
          passOn: {
            render: props => <div>skills {props.roleName}</div>
          }
        },
        {
          pattern: "/",
          passOn: {
            render: props => <HomeSection />
          }
        }
      ],
      whenRouteChange: (pattern, passOn, params) => {
        const { render } = passOn || {};
        const route = (
          <Fragment>{typeof render === "function" && render(params)}</Fragment>
        );
        this.setState({ route });
      }
    });
    this.setState({ router }, () => {
      router.checkFragment();
    });
  }
  render() {
    const { route } = this.state;
    return route || "loading...";
  }
}
export default Routes;
