import React, { Fragment } from "react";
import Tab from "@skc/block-skillscloud/tab-group";
import Card from "@skc/block-skillscloud/card";
import RoleCompetencesCard from "./RoleCompetencesCard";
import {
  listRelatedOfRole,
  asLabel,
  asDescription
} from "@skc/domain-skillscloud/backoffice/utils";

const { Title, Section } = Card;

const Collapsible = props => {
  const { children } = props;
  return <Fragment>{children}</Fragment>;
};

const RoleCard = props => {
  const { role } = props;
  const titlecase = "titlecase";

  const roleName = asLabel(titlecase)(role);
  const roleDesc = asDescription(titlecase)(role);
  const roleRelated = (listRelatedOfRole(role) || [])
    .map(asLabel(titlecase))
    .join(",");
  const areaOfInterest = "areaOfInterest";

  /*
  const x = {
    const roleNamed: m && m.i18n_fr,
    const roleDescription: m && m.Description,
    const roleRelated: m && m.related,
    const tasks = role.taks.map(asLabel)
    .join(",")
  };
  */

  if (!role) {
    return "";
  }

  return (
    <Tab.Group>
      <Tab.Item title="Description" uid="description">
        <Title>{roleName}</Title>
        <Section title="Job Description">
          <Collapsible>{roleDesc}</Collapsible>
        </Section>
        <Section title="Related Roles">{roleRelated}</Section>
        <Section title="Area Of Interest">{areaOfInterest}</Section>
      </Tab.Item>
      <Tab.Item title="Competences" uid="competences">
        <Section title="Tasks">
          <RoleCompetencesCard {...{ role }} />
        </Section>
      </Tab.Item>
    </Tab.Group>
  );
};

export default RoleCard;
