import React, { Component } from "react";

import template from "./__wireframe__/landing.svg";
import newestOccupations from "./__wireframe__/newest-occupations.svg";
import topCategories from "./__wireframe__/top-categories.svg";
import searchBox from "./__wireframe__/search-box.svg";

class SearchSection extends Component {
  render() {
    return (
      <div>
        Home Section
        <br />
        <img src={template} alt="template" />
        <br />
        <img src={searchBox} alt="template" />
        <br />
        <img src={newestOccupations} alt="template" />
        <br />
        <img src={topCategories} alt="template" />
      </div>
    );
  }
}

export default SearchSection;
