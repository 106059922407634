import React, { Component, Fragment } from "react";
import TermSearch from "@skc/block-skillscloud/term-search";
import RoleCard from "@skc/block-skillscloud/role-card";
import { findRoleByName } from "@skc/domain-skillscloud/backoffice/utils";

class RolesExplorer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.bound = {
      onRoleChange: name => this.setState({ role: findRoleByName(name) })
    };
  }
  render() {
    const { onRoleChange } = this.bound;
    const { role } = this.state;
    console.log("ROLE", role);

    return (
      <Fragment>
        <TermSearch
          prompt="Search for a role"
          whenChange={onRoleChange}
          placeholder="rolename here..."
        />
        <RoleCard role={role} />
      </Fragment>
    );
  }
}

export default RolesExplorer;
