import styled from "styled-components";

export const CardTitle = styled.h1`
  color: white;
  font-size: 24px;
  background-color: #0a1d3b;
  display: block;
  margin-top: 30px;
  margin-bottom: 10px;
  padding-left: 10px;
`;

export const SectionTitle = styled.h4``;

export const SectionContent = styled.div``;
