import React from "react";
import { Helmet } from "react-helmet";

export const i18n = {
  appTitle: "SkillsCloud",
  canonical: "https://www.begreator.com/"
};

const SiteHelmet = props => {
  const { appTitle, canonical } = { ...i18n, ...props };

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{appTitle}</title>
      <link rel="canonical" href={canonical} />
      <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
    </Helmet>
  );
};

export default SiteHelmet;
