import styled from "styled-components";

export const Styled = styled.div`
  ul#hardSkills {
    color: black;
  }

  ul#hardSkills a {
    text-decoration: none;
  }

  /* Manage the hiding/showing of paragraph
   Usage:
       <div class="sectionHeader">
        <h3> "You Title Text" </h3>
        <button type="button" (click)="set...Toggle()">{{toggle.buttonName}}</button>
    </div>
    Where :
            toggle: is the data structure defined via interface toggle.ts and used in the component.
                    import interface/toggle.ts inside the component
            set...Toggle is the name of the function in the Component to be called.

*/

  div.hr-line {
    /* Generate a line separator
       Usage: add a div in the html document: <div class="hr-line"></div>
    */
    position: relative;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    width: 100%;
    border-bottom: 1px solid #7a7a7a;
  }

  .tasksColumns {
    display: flex;
    flex-direction: row;
  }

  .form {
    margin-bottom: 30px;
  }

  .tabspace {
    margin-top: 30px;
  }

  .rolesColumns {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }

  .rolesColumnsLeft {
    width: 18%;
  }

  .rolesColumnsRight {
    width: 82%;
  }

  h2 {
    color: white;
  }

  h4 {
    font-size: 15px;
  }
`;

export const Bordered = styled.h3`
  border-bottom: 1px solid grey;
  margin-bottom: 10px;
`;
