import React, { Component } from "react";
import partners from "./__wireframe__/partners-section.svg";
import { Styled, Listing } from "./styled";
import ecam from "./etc/ecam.png";
import equal from "./etc/equal-brussels.png";
import tonic from "./etc/tonic.png";

class PartnersSection extends Component {
  render() {
    return (
      <Styled>
        <h1>Our partners</h1>
        <Listing>
          <img src={equal} />
          <img src={ecam} />
          <img src={tonic} />
        </Listing>
      </Styled>
    );
  }
}

export default PartnersSection;
