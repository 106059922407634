export const table = [
  {
    slug: "data_scientist",
    i18n_fr: "Data Scientist",
    Description:
      "Data scientists translate business objectives into data science problems. They find and interpret rich data sources\t manage large amounts of data\t merge data sources\t ensure consistency of data-sets\t and create visualisations to aid in understanding data. They build mathematical models using data\t present and communicate data insights and findings to specialists and scientists in their team and if required\t to a non-expert audience\t and recommend ways to apply the data.",
    related: [
      "recQXoXR5BCWdLyDA",
      "recc2uRO6Y1vfKjxg",
      "recbyjUIIxjwQRne2",
      "reclbRnoQLSMWJN3a",
      "rec2xwN1GQFhJwM3F"
    ],
    tasks: [
      "reczKDpDApvyj4BqJ",
      "reci5TT0796QDAnmt",
      "recjnWPCBaKAPaoxr",
      "reciGRemUY6h8JQuu",
      "reczrWbVHTZDLKpcn",
      "recrhDzBZbcGBxtGs",
      "receTkjf9nDeIftj4",
      "recXljT6tl3ymvxHS",
      "recGCSatmE4QunfPi",
      "recQqe8MmkzujAVEn",
      "rec7qmZpxxVriS83v",
      "recinnuSKAv8Cnm8Q"
    ],
    xxx: "data_scientist",
    _id: "rec3tS6ps6yNdt8XM"
  },
  {
    slug: "data_analyst",
    i18n_fr: "Data Analyst",
    Description:
      "Data analysts import\t inspect\t clean\t transform\t validate\t model\t or interpret collections of data with regard to the business goals of the company. They ensure that the data sources and repositories provide consistent and reliable data. Data analysts use different algorithms and IT tools as demanded by the situation and the current data. They might prepare reports in the form of visualisations such as graphs\t charts\t and dashboards.",
    related: [
      "reclbRnoQLSMWJN3a",
      "rec3tS6ps6yNdt8XM",
      "recbyjUIIxjwQRne2",
      "recc2uRO6Y1vfKjxg"
    ],
    tasks: [
      "recRD24JlFcMndhbq",
      "recM6OAx4A1rYMPzW",
      "recnuemonvciwOoOn",
      "reci5TT0796QDAnmt",
      "recn7tjZNcB5hWA3R",
      "reciGRemUY6h8JQuu",
      "recrhDzBZbcGBxtGs",
      "receTkjf9nDeIftj4",
      "recXljT6tl3ymvxHS",
      "rec0LFCXh3glkLIms",
      "recQqe8MmkzujAVEn"
    ],
    xxx: "data_analyst",
    _id: "recQXoXR5BCWdLyDA"
  },
  {
    slug: "software_engineer",
    i18n_fr: "Software Engineer",
    Description:
      "Software engineers apply the principles of software engineering to the design\t development\t maintenance\t testing\t and evaluation of computer software.",
    related: [
      "recfji2iMN6pE7sYC",
      "rec2xwN1GQFhJwM3F",
      "recJmJPdzb2PWy33o",
      "recsQxBoafkoQZB6h",
      "recZ8WDo4a59fdxhQ",
      "rec56wvX50nNte6AD"
    ],
    tasks: [
      "recnn0hTJvwiRWvlJ",
      "recgImttwTpwyK2pk",
      "rece8nxsVQHOLeIky",
      "recAXTCXPAEYiAM7u",
      "recl4BeEAMrPWzivy",
      "rec9kkPLQNk6A3UuC",
      "recL4sF7mQp38frm2",
      "recL7PQRj9kup1fx0",
      "recdRdvztfZRhVv0v",
      "rec1gRFxu5PpACT6m",
      "recRwFTe1mtwqLCcq",
      "recSYRbViANMBLi9l",
      "rec0LFCXh3glkLIms",
      "rec9W6amc0X0xwO0S",
      "recj59I86qlX3mFSZ",
      "reczmQFT5fBmz2taI",
      "rec3lk8cU6hPvbCUC",
      "recJFNZr4xJr0YXD2",
      "recl1U1WjQP3uTDvJ",
      "rec2F9rihT3VAelPM",
      "recpymrM3euHuo8eY",
      "recTLkVqV6WQefaMi",
      "rec0rQ2MyOoJoxX4K",
      "recILs7mhwsGPeM2m",
      "recExqbEz4ddUxFav"
    ],
    xxx: "software_engineer",
    _id: "recZAf3eYRbuxD2DV"
  },
  {
    slug: "database_administrator",
    i18n_fr: "Database Administrator",
    Description:
      "Database administrators test\t implement and administer computer databases. They use their expertise in database management systems to plan\t coordinate and implement security measures to safeguard computer databases. They also use scripts and configuration files to tailor a database to users' needs.",
    related: [
      "rectIJhwyvAVudgtf",
      "rec4l2zR0xvqyrGUV",
      "recNu6OgSwjllsOvp",
      "recI0nQ8yAtVJkDxp"
    ],
    tasks: [
      "recnn0hTJvwiRWvlJ",
      "recgImttwTpwyK2pk",
      "recw5oesT6KxEPqlP",
      "reciTTtC0oymQpBq5",
      "rec7BwmL6lwKy0wIB",
      "recRwFTe1mtwqLCcq",
      "rec74bQ6d9DVtOZnB",
      "rec8d7OVyPYNkPhZS",
      "reckInSo5s5292JJg",
      "recI0yzHcqbV9JILb",
      "recl1U1WjQP3uTDvJ"
    ],
    xxx: "database_administrator",
    _id: "recGjy4kXskTyW0Z1"
  },
  {
    slug: "java_developer",
    i18n_fr: "Java Developer",
    Description:
      "Java developers implement or program all kinds of software systems based on specifications and designs by using the Java programming language.",
    related: [
      "recsQxBoafkoQZB6h",
      "recZAf3eYRbuxD2DV",
      "recZ8WDo4a59fdxhQ",
      "rec56wvX50nNte6AD"
    ],
    tasks: [
      "recgImttwTpwyK2pk",
      "rece8nxsVQHOLeIky",
      "rec9kkPLQNk6A3UuC",
      "recEgCpGeGLFJWTsV",
      "recqnDjjIvdfmiqf8",
      "recRwFTe1mtwqLCcq",
      "recSYRbViANMBLi9l",
      "rec0LFCXh3glkLIms",
      "recy6mJPcYxBqtEKX",
      "rec9W6amc0X0xwO0S",
      "receFvIZIE5mjeVt8",
      "reccpJl4kljvhFH26",
      "recxZ0ciHmxroa0sE",
      "rec7qmZpxxVriS83v",
      "recj59I86qlX3mFSZ",
      "reczmQFT5fBmz2taI",
      "recrSgWc9kJ9H20Iv",
      "rec23A4sYpb6azRct",
      "recJCVOTFfc2EjdNS",
      "rec3NjZeCim6rgoY3",
      "reczaQMnOeLu1ZtiF",
      "recYPDeXZxVC0pm0H",
      "recmJroKIf6EaWT4j",
      "recgyaouQIQbxZup3",
      "recCieS1adNjluHIR",
      "recl6jv2okR00F9ZJ",
      "rect8WK1XqiZDjePz",
      "rec2F9rihT3VAelPM"
    ],
    xxx: "java_developer",
    _id: "recJmJPdzb2PWy33o"
  },
  {
    slug: "python_developer",
    i18n_fr: "Python Developer",
    Description:
      "Python developers implement or program all kinds of software systems based on specifications and designs by using the Python programming language.",
    related: [
      "rec2xwN1GQFhJwM3F",
      "recZAf3eYRbuxD2DV",
      "recsQxBoafkoQZB6h",
      "recZ8WDo4a59fdxhQ",
      "rec56wvX50nNte6AD"
    ],
    tasks: [
      "recgImttwTpwyK2pk",
      "recM6OAx4A1rYMPzW",
      "rece8nxsVQHOLeIky",
      "rec9kkPLQNk6A3UuC",
      "recJP0Czoiu38oFyX",
      "recO7DzlpuG6sVqxF",
      "recEgCpGeGLFJWTsV",
      "recfHEURDctM5SBJ5",
      "recqnDjjIvdfmiqf8",
      "recRwFTe1mtwqLCcq",
      "recSYRbViANMBLi9l",
      "recI0yzHcqbV9JILb",
      "recuiCHr9ARNxfQvO",
      "rec0LFCXh3glkLIms",
      "rec2f7gCjGqZMFQm0",
      "recy6mJPcYxBqtEKX",
      "rec9W6amc0X0xwO0S",
      "receFvIZIE5mjeVt8",
      "reccpJl4kljvhFH26",
      "recxZ0ciHmxroa0sE",
      "rec7qmZpxxVriS83v",
      "recj59I86qlX3mFSZ",
      "reczmQFT5fBmz2taI",
      "recJCVOTFfc2EjdNS",
      "recYPDeXZxVC0pm0H",
      "recj60hMGvgCYQbZh",
      "receYERyWVbVJFgLo",
      "recuvv1H36ugLc5G1",
      "recDdu5xmH10S8ArN",
      "recpymrM3euHuo8eY"
    ],
    xxx: "python_developer",
    _id: "rec2xwN1GQFhJwM3F"
  },
  {
    slug: "cobol_programmer",
    i18n_fr: "COBOL Programmer",
    Description:
      "COBOL Programmers implement or program all kinds of software systems based on specifications and designs by using the COBOL programming language.",
    related: ["recUmTQ12Vjqf1PYE", "recoXbbDSWCDDQnXF", "rect9NHpKEWQ3nU3k"],
    tasks: ["rec15mA9yjHa9ky61"],
    xxx: "cobol_programmer",
    _id: "recjQM2NDy0Nwdxqx"
  },
  {
    _id: "rec0XVgzaIX9XDLbk"
  },
  {
    _id: "recRjmk0umNZAB41a"
  },
  {
    _id: "reci96LFknwfGJ53Y"
  },
  {
    _id: "reczm4lCoNd5s2NSB"
  },
  {
    _id: "recXctEiZcAL3BYKT"
  },
  {
    _id: "rec3O8ZEH40nYoo7h"
  },
  {
    _id: "recsBiN2bN6KBwuOt"
  },
  {
    _id: "rec9H4GYZjG2CJQkH"
  },
  {
    _id: "rec61aqAynhI5svpE"
  },
  {
    _id: "recgsrYe9YfW7IRCD"
  },
  {
    _id: "reckd1fgzwsrUOubD"
  },
  {
    _id: "rec4Gil9kPSg9npg5"
  },
  {
    _id: "recy2mXCBinKhl726"
  },
  {
    _id: "recc4pttsMnIQdfDR"
  },
  {
    _id: "reco9zSOcTB1vTF5n"
  },
  {
    _id: "recJX3bpjMkmFaaLM"
  },
  {
    _id: "recVvsBCFG8Wurfw8"
  },
  {
    _id: "recVmbRoRN258tn1Y"
  },
  {
    _id: "rec1XCLII4yhSxUg2"
  },
  {
    _id: "recpUPqzo5Zpd1NSx"
  },
  {
    _id: "recsMCPPaN1SpFXqj"
  },
  {
    _id: "recRDK5ninR49SrEn"
  },
  {
    _id: "recaYHjIGZpYx36vq"
  },
  {
    _id: "recVUFobzgThaAYX7"
  },
  {
    _id: "recQ2JJ1GbGEUGM6l"
  },
  {
    _id: "recXmFLsNUEMwYEI8"
  },
  {
    _id: "recivxN6pGUjAW70n"
  },
  {
    _id: "rec5i9m8ksgjXh116"
  },
  {
    slug: "Bi",
    _id: "recc2uRO6Y1vfKjxg"
  },
  {
    slug: "Data Architect",
    _id: "recbyjUIIxjwQRne2"
  },
  {
    slug: "Data Engineer",
    _id: "reclbRnoQLSMWJN3a"
  },
  {
    slug: "C++ developer",
    _id: "recfji2iMN6pE7sYC"
  },
  {
    slug: "Full Stack Developer",
    _id: "recsQxBoafkoQZB6h"
  },
  {
    slug: "Software Architect",
    _id: "recZ8WDo4a59fdxhQ"
  },
  {
    slug: "Software Analyst",
    _id: "rec56wvX50nNte6AD"
  },
  {
    slug: "Database designer",
    _id: "rectIJhwyvAVudgtf"
  },
  {
    slug: "Database Analyst",
    _id: "rec4l2zR0xvqyrGUV"
  },
  {
    slug: "Database developer",
    _id: "recNu6OgSwjllsOvp"
  },
  {
    slug: "Database engineer",
    _id: "recI0nQ8yAtVJkDxp"
  },
  {
    slug: "Mainframe programmer",
    _id: "recUmTQ12Vjqf1PYE"
  },
  {
    slug: "COBOL Software Engineer",
    _id: "recoXbbDSWCDDQnXF"
  },
  {
    slug: "Analyst programmer",
    _id: "rect9NHpKEWQ3nU3k"
  }
];
