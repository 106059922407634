import React, { Fragment } from "react";
import Card from "@skc/block-skillscloud/card";
import { VBox } from "@iml/design-layout";
import { Bordered } from "./styled";
import { getTaskById, asLabel } from "@skc/domain-skillscloud/backoffice/utils";
const { Title } = Card;

const TaskItem = props => {
  const { active, getTaskSkills, item } = props;
  const task = getTaskById(item);
  const name = asLabel("")(task);

  return (
    <button
      mat-button
      onClick={getTaskSkills}
      primary={active && "primary"}
      capitalize
    >
      {name}
    </button>
  );
};

const ChipList = props => {
  const { items } = props;
  return (
    <mat-chip-list>
      {(items || []).map(d => (
        <mat-chip color="primary">{d}</mat-chip>
      ))}
    </mat-chip-list>
  );
};

const TaskSkillItem = props => (
  <div simpleFadeAnimation="'in'">{props.task}</div>
);

const isActive = d => true;

const RoleCompetencesCard = props => {
  const { role } = props;
  // const isActiveTask = true; // task.name !== selectedTask;

  const tasks = role.tasks;
  const taskSkills = undefined;
  const hardskills = undefined;

  return (
    <Fragment>
      <VBox width={40}>
        <Bordered>Task</Bordered>
        {(tasks || []).map(d => (
          <TaskItem active={isActive(d)} item={d} />
        ))}
      </VBox>
      <VBox style={{ marginLeft: "50" }}>
        <Bordered>Skills related to the task selected</Bordered>
        {(taskSkills || []).map(d => (
          <TaskSkillItem task={d} />
        ))}
      </VBox>
      <div>
        <Title>Hard skills</Title>
        <ChipList items={hardskills /*of skillsSet*/} />
        <ul id="hardSkills"></ul>
      </div>
    </Fragment>
  );
};

export default RoleCompetencesCard;
