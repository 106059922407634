import React from "react";
import { HBox, VBox } from "@iml/design-layout";
import { Styled, NavLink } from "./styled";

const LinkBlank = props => {
  const { children, href } = props;

  return (
    <NavLink href={href} rel="noopener noreferrer" target="_blank">
      {children}
    </NavLink>
  );
};

const NavBar = props => {
  const { orientation } = props;
  const Container = orientation === "vertical" ? VBox : HBox;
  return (
    <Styled>
      <Container>
        <NavLink routerlink="/explore">Library</NavLink>
        <NavLink routerlink="/faq">FAQ</NavLink>
        <LinkBlank href="https://www.begreator.com/contact">Contact</LinkBlank>
      </Container>
    </Styled>
  );
};

export default NavBar;
