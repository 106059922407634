import React, { Component, Fragment } from "react";
import TermSearch from "@skc/block-skillscloud/term-search";
import SkillCard from "@skc/block-skillscloud/skill-card";
import { findSkillByName } from "@skc/domain-skillscloud/backoffice/utils";

class SkillsExplorer extends Component {
  constructor(props) {
    super(props);
    this.bound = {
      onSkillChange: name => this.setState({ skill: findSkillByName(name) })
    };
    this.state = { skill: undefined };
  }
  render() {
    const { skill } = this.state;
    const { onSkillChange } = this.bound;

    return (
      <Fragment>
        <TermSearch
          prompt="Search for a skill"
          whenChange={onSkillChange}
          placeholder="skillname here..."
        />
        <SkillCard skill={skill} />
      </Fragment>
    );
  }
}

export default SkillsExplorer;
