import React from "react";
import { Styled, Middle } from "./styled";
import { HSpacer, HBox, VSpacer } from "@iml/design-layout";
import logo from "./etc/logo.png";
import begreator from "./etc/begreator.png";

import SocialBar from "@skc/block-skillscloud/skillscloud-socialbar";
import NavBar from "@skc/block-skillscloud/skillscloud-navbar";

// const BeGreatorBlah = <Section>Powered by BeGreator</Section>;

const SiteFooter = props => {
  return (
    <Styled>
      <HSpacer size={32} />
      <img src={logo} alt="logo" height={98} />
      <HSpacer size={72} />
      <Middle>
        <img src={begreator} alt="begreator" />
        <VSpacer size={24} />
        <HBox>
          <div>
            BeGreator is a tech startup that develops AI driven technologies to
            close the education-to-employment gap and guarantee fair, objective,
            skill-driven talent management practices
          </div>
          <HSpacer size={360} />
          <NavBar orientation="vertical" />
        </HBox>
        <VSpacer size={24} />
        <SocialBar />
      </Middle>
    </Styled>
  );
};

SiteFooter.propTypes = {};

export default SiteFooter;

/*
<Tiles>
  <Tiles.i />
  <Tiles.i>{BeGreatorBlah}</Tiles.i>
  <Tiles.i />
</Tiles>
*/
