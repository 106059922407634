import React from "react";
import NavBar from "@skc/block-skillscloud/skillscloud-navbar";
import LanguagePicker from "@skc/block-skillscloud/skillscloud-language-picker";
import { Styled, SpreadOut } from "./styled";
import { HSpacer } from "@iml/design-layout";
import logo from "./etc/logo.png";
import begreator from "./etc/begreator.png";

const Masthead = () => {
  return (
    <Styled>
      <SpreadOut>
        <HSpacer size={32} />
        <img src={logo} alt="logo" height={98} />
        <HSpacer size={24} />
        <SpreadOut>
          <img src={begreator} alt="begreator" />
          <NavBar />
        </SpreadOut>
        <HSpacer size={24} />
        <LanguagePicker />
        <HSpacer size={32} />
      </SpreadOut>
    </Styled>
  );
};

Masthead.propTypes = {};

export default Masthead;
