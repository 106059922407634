import React, { Component } from "react";
import { VSpacer, HSpacer } from "@iml/design-layout";
import { Styled, HomeStyled, Left, PythSpan, OnSpan } from "./styled";

import template from "./__wireframe__/home-landing-page.svg";
import BeneficiariesSection from "@skc/block-skillscloud/beneficiaries-section";
import BenefitsSection from "@skc/block-skillscloud/benefits-section";
import RecentlyAddedSection from "@skc/block-skillscloud/recently-added-section";
import StillHaveQuestionSection from "@skc/block-skillscloud/still-have-question-section";
import PartnersSection from "@skc/block-skillscloud/partners-section";

import scene from "./etc/scene.svg";

const button = <button>Explore our library</button>;

const Home = () => {
  return (
    <HomeStyled>
      <Left>
        <h1>
          Discover
          <br />
          <PythSpan>pyth</PythSpan>
          <OnSpan>on</OnSpan>
        </h1>
        <VSpacer size={12} />
        <div>
          Find out about the most popular tech occupations & skills collected
          from job posting, academic websites and renown repositories.
        </div>
        <VSpacer size={48} />
        <div>
          {button}
          <HSpacer size={8} />
          <button className="secondary">Learn More</button>
        </div>
      </Left>
      <img src={scene} />
    </HomeStyled>
  );
};

class HomeSection extends Component {
  render() {
    const latest = [{ title: "job" }];
    return (
      <Styled>
        <Home />
        <VSpacer size={120} />
        <BeneficiariesSection button={button} />
        <VSpacer size={120} />
        <BenefitsSection />
        <VSpacer size={120} />
        <RecentlyAddedSection items={latest} button={button} />
        <VSpacer size={120} />
        <StillHaveQuestionSection items={latest} button={button} />
        <VSpacer size={120} />
        <PartnersSection />
        <VSpacer size={120} />
      </Styled>
    );
  }
}

export default HomeSection;
