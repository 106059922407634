import styled from "styled-components";

export const Styled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 120px;
  justify-content: center;
  justify-items: center;
  align-items: center;

  font-family: Montserrat;
  font-style: normal;
  line-height: 30px;

  h1 {
    font-weight: 600;
    font-size: 24px;
  }

  font-weight: normal;
  font-size: 16px;
  /* or 187% */
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 480px;
  justify-content: center;
  justify-items: center;
`;
