import { table as skills } from "@skc/backoffice-data/skills";
import { table as tasks } from "@skc/backoffice-data/tasks";
import { table as roles } from "@skc/backoffice-data/roles";

const identity = d => d;
export const asLabel = (deflt = "") => d => {
  if (!d) {
    return deflt;
  }
  return d.hasOwnProperty("i18n_fr") ? d.i18n_fr : d.slug;
};

export const asDescription = deflt => d =>
  d && d.hasOwnProperty("Description") ? d.Description : deflt;

const nameMatches = name => d =>
  asLabel("")(d)
    .toLowerCase()
    .includes(name.toLowerCase())
    ? true
    : false;

export const findByName = table => name => {
  if (typeof name !== "string") {
    return;
  }
  return table.find(nameMatches(name));
};

export const findSkillByName = findByName(skills);
export const findRoleByName = findByName(roles);

const byId = id => d => d._id === id;
export const findTaskById = id => {
  return tasks.find(byId(id));
};

export const findRoleById = id => {
  return roles.find(byId(id));
};

export const listTasksOfSkill = (obj, transform) =>
  ((obj && obj.tasks) || []).map(findTaskById).map(transform || identity);

export const listTasks = obj => {
  if (!obj) {
    return [];
  }
  return (obj.tasks || []).map(id => {
    return tasks.find(d => d._id === id);
  });
};

export const getTaskById = ref => {
  return tasks.find(d => d._id === ref);
};

export const listRelatedOfRole = (obj, transform) => {
  return ((obj && obj.related) || [])
    .map(findRoleById)
    .map(transform || identity);
};

/*

/roles
select distinct name from roles;


/skills
select distinct name from skills;

--------------------

/role/search/:name

CALL getFullRoleDetails( ? , @rolename, @roledesc, @relatedroles, @taskname, @skillname );

CREATE PROCEDURE getFullRoleDetails (IN jobname VARCHAR (30),
					OUT rolename VARCHAR (30),
					OUT roledesc VARCHAR(2048),
					OUT relatedroles VARCHAR(2048),
					OUT taskname VARCHAR (255),
					OUT skillname VARCHAR (30)
                                    )
BEGIN
	SELECT r.name AS rolename, r.description AS roledesc, r.RelatedRoles AS relatedroles, t.name AS taskname, s.name AS skillname
	FROM roles AS r
	JOIN rolesTasks AS rt ON r.id = rt.idrole
	JOIN tasks AS t ON rt.IDtask = t.id
	JOIN tasksskills AS ts ON ts.IDtask = t.id
	JOIN skills AS s ON s.id = ts.IDskill
	WHERE r.name = jobname
    ORDER BY taskname, skillname;
END //

let role = '';
let roledesc = '';

if (rows[0] != '' ) {

	let tasksArray = [];							// Create an empty array for storing the sets values for later use.

	// Role structure:
	// Role {
	//        roleName: string,						// The role's name.
	//		  roleDescription: string,				// The role's description.
	//		  tasks: task[]							// An array (list) f tasks objects (see. below).
	//}
	Role = {};										// Defines a Role object.
	Role.roleName = rows[0][0].rolename;			// Getting the role name.
	Role.roleDescription = rows[0][0].roledesc;		// Getting the role description.
	Role.relatedRoles = rows[0][0].relatedroles;		// Getting the role description.
	Role.tasks = [];

	// Task structure:
	// Task {
	//		name: string,							// the task name.
	//		skills: string[]						// each skill names of that task.
	//}
	Task = {};										// placeholder for taks.name and task.skills[]
	Task.name = rows[0][0].taskname
	Task.skills = [];

	rows[0].forEach( (row) => {
		if ( row.taskname == Task.name ) {
			Task.skills.push( row.skillname );						// Pushing task's skills.
		} else {
			console.log('>>>> ...Pushing Task object in Role !', Task);
			Role.tasks.push( Task );		// New task detected, pushing current task object in Role.task array.

			Task = {};						// Creating new empty task object.
			Task.name =  row.taskname;		// Assign new detected task.
			Task.skills = [];				// Initializing skills array.

			Task.skills.push(row.skillname);
		}
	});

	res.send( Role );

} else {
	// handling empty SQL result case.
	console.log("Empty SQL Results for Roles");
	Role = {};
	Role.roleName = rolename;
	Role.roleDescription = "Is unknown from our skillsCloud database. Retry with another word or spelling"
	Role.relatedRoles =  ""
	res.send( Role );
}// end if


-------


/skill/search/:name

CALL getFullSkillDetails( ?, @skilldesc, @rolename, @taskname );


CREATE PROCEDURE getFullSkillDetails ( IN skillname VARCHAR(30),
        OUT skilldesc VARCHAR(255),
        OUT rolename VARCHAR(30),
        OUT taskname VARCHAR(255)
                                    )
BEGIN
SELECT s.name AS skillname, s.description AS skilldesc, r.name AS rolename, t.name AS taskname
FROM skills AS s
JOIN tasksskills AS ts ON ts.IDskill = s.id
JOIN tasks AS t ON ts.IDtask = t.id
JOIN rolesTasks AS rt ON rt.IDtask = t.id
JOIN roles AS r ON r.id = rt.IDrole
WHERE s.name = skillname;
END //

if (rows[0] != '' ) {
	Skill = {};
	Skill.skillname = rows[0][0].skillname;
	Skill.skilldesc = rows[0][0].skilldesc;
	Skill.tasks = [];
	Skill.roles = [];
	tasks = new Set();
	roles = new Set();
	rows[0].forEach( (row) => {
		tasks.add(row.taskname);
		roles.add(row.rolename);
	});
	Skill.tasks = Array.from( tasks );
	Skill.roles = Array.from( roles );

	res.send(Skill);
}
else {
	// handling empty SQL result case.
	console.log("Empty SQL Results for Skills");
	Skill = {};
	Skill.skillname = skillname;
	Skill.skilldesc = "Is unknown from our skillsCloud database. Retry with another word or spelling"
	res.send( Skill );
}


*/
