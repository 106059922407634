import styled from "styled-components";

export const HomeStyled = styled.div`
  display: flex;
  flex-orientation: row;
  justify-content: space-between;

  background: #d8f0f5;
  border-radius: 21px;
  margin: 32px;
  box-sizing: border-box;
  padding: 64px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;

  h1 {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: 900;
    font-size: 60px;
    line-height: 80px;
  }
`;

export const PythSpan = styled.span`
  color: #2a899d;
  border-right: 1px solid black;
`;
export const OnSpan = styled.span`
  color: rgba(42, 137, 157, 0.2);
`;

export const Styled = styled.div`
  button {
    width: 261px;
    height: 65px;
    left: 589px;
    top: 723px;

    background: #2a899d;
    border: 1px solid #2a899d;
    box-sizing: border-box;
    border-radius: 10px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    /* identical to box height, or 167% */

    color: #ffffff;
  }
`;

export const Bordered = styled.h3`
  border-bottom: 1px solid grey;
  margin-bottom: 10px;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 472px;
  button {
    width: 210px;
  }
  button.secondary {
    background: #ffffff;
    border: 1px solid #2a899d;
    box-sizing: border-box;
    border-radius: 10px;
    color: #2a899d;
  }
`;
