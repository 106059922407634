import React, { Component } from "react";
import faqMenu from "./__wireframe__/faq-menu.svg";

class FaqSection extends Component {
  render() {
    return (
      <div>
        FAQ Section
        <img src={faqMenu} alt="alt" />
      </div>
    );
  }
}

export default FaqSection;
