import styled from "styled-components";

export const Styled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: top;
  background-color: #d8f0f5;
  padding-top: 32px;
  padding-bottom: 32px;
`;

export const Middle = styled.div`
  width: 860px;
  line-height: 2.1;
`;
