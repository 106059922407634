import React, { Component } from "react";
import { VSpacer } from "@iml/design-layout";
import { Styled, CardStyled, Listing } from "./styled";

import template from "./__wireframe__/recently-added-section.svg";

const Card = props => {
  const { title } = props;
  return (
    <CardStyled>
      <h1>{title}</h1>
    </CardStyled>
  );
};

const RecentlyAddedSection = props => {
  const { items, button } = props;
  return (
    <Styled>
      <h1>Newly added occupations & skills</h1>
      <Listing>{items.map(Card)}</Listing>
      <VSpacer size={32} />
      {button}
    </Styled>
  );
};

export default RecentlyAddedSection;
