import styled from "styled-components";

export const Styled = styled.div`
  label {
    padding-right: 20px;
  }

  input {
    border-radius: 3px;
    border: 0.5px;
    background-color: rgb(227, 242, 255);
    max-width: 450px;
    width: 35%;
    height: 45px;
    min-width: 250;
    padding-left: 10px;
    padding-right: 10px;
  }

  button {
    margin-left: 5px;
    color: white;
    background: #0a1d3b;
    font-weight: lighter;
    border: 0.5px;
    border-radius: 2px;
    height: 45px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;
