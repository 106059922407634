import React from "react";
import { Styled } from "./styled";
import youtube from "./etc/youtube.png";
import facebook from "./etc/facebook.png";
import linkedin from "./etc/linkedin.png";
import instagram from "./etc/instagram.png";

const SocialBar = props => {
  return (
    <Styled>
      <a href="http://facebook.com">
        <img src={facebook} alt="facebook" />
      </a>
      <a href="http://youtube.com">
        <img src={youtube} alt="youtube" />
      </a>
      <a href="http://linkedin.com">
        <img src={linkedin} alt="linkedin" />
      </a>
      <a href="http://instagram.com">
        <img src={instagram} alt="instagram" />
      </a>
    </Styled>
  );
};

export default SocialBar;
