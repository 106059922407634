import styled from "styled-components";

export const Styled = styled.div`
  box-sizing: content-box;
`;

export const NavLink = styled.a`
  padding-left: 40px;
  text-decoration: none;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
`;
