import React, { Fragment } from "react";

const withHelmet = Component => props => {
  const { helmet, ...others } = props;
  return (
    <Fragment>
      <Component {...others} />
      {helmet}
    </Fragment>
  );
};

export default withHelmet;
