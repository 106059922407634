import styled from "styled-components";

export const Styled = styled.div`
  text-align: center;
  h1 {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: 900;
    font-size: 34px;
    line-height: 80px;
    /* identical to box height, or 235% */
    text-align: center;
  }
`;

export const CardStyled = styled.div`
  border: 1px solid red;
`;

export const Listing = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
