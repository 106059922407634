export const table = [
  {
    "slug": "administer_ict_system",
    "i18n_fr": "administer ICT system",
    "knowledge": [
      "recm183xXmjaPphLT",
      "recDTHj0nClcIQRvr",
      "recfTUqjHvRReoagd",
      "recPmKxRA8fDPl0au",
      "recqhbL8hgDH3D4bJ",
      "recBJ8735QF1Mq63i",
      "reclgK82dFLXwf9Dk",
      "reczXLBkeKjFBYo6e"
    ],
    "_id": "recvTA7UA5BvSZzUi"
  },
  {
    "slug": "analyse_big_data",
    "i18n_fr": "analyse big data",
    "knowledge": [
      "recVndG0g0lKKjBRA",
      "recuxwbEUr0a5uSbs",
      "reccshxi28Bv59Lp5",
      "recOkwp8p0pHwltsB"
    ],
    "_id": "rechJBLjWDlFNQ7zE"
  },
  {
    "slug": "analyse_network_bandwidth_requirements",
    "i18n_fr": "analyse network bandwidth requirements",
    "skills": [
      "recD8MIIKPzqwvaZV"
    ],
    "knowledge": [
      "recGZW20Z8xRqgIgM",
      "recIbxElF48MPD78Z"
    ],
    "_id": "recIuwYpnHqxLKcHh"
  },
  {
    "slug": "analyse_software_specifications",
    "i18n_fr": "analyse software specifications",
    "roles": [
      "recZAf3eYRbuxD2DV",
      "recGjy4kXskTyW0Z1"
    ],
    "knowledge": [
      "recfnCmt1maRf8n81",
      "recAU2sOEdNdcNOOH",
      "recMQU4mcWb1BcOro"
    ],
    "_id": "recnn0hTJvwiRWvlJ"
  },
  {
    "slug": "apply_information_security_policies",
    "i18n_fr": "apply information security policies",
    "roles": [
      "recZAf3eYRbuxD2DV",
      "recGjy4kXskTyW0Z1",
      "recJmJPdzb2PWy33o",
      "rec2xwN1GQFhJwM3F"
    ],
    "knowledge": [
      "recUXUc6b3OKnpIea",
      "recozIYEngsVQevUa"
    ],
    "_id": "recgImttwTpwyK2pk"
  },
  {
    "slug": "apply_statistical_analysis_techniques",
    "i18n_fr": "apply statistical analysis techniques",
    "roles": [
      "recQXoXR5BCWdLyDA"
    ],
    "knowledge": [
      "recOkwp8p0pHwltsB",
      "recSgW03PW38JRF5w"
    ],
    "_id": "recRD24JlFcMndhbq"
  },
  {
    "slug": "balance_database_resources",
    "i18n_fr": "balance database resources",
    "skills": [
      "recGtJbHSOEXpVXoV",
      "recYns0OKCQY3r7sa",
      "reccetSZBvLlbFTRh",
      "recjDYugUQsZSIQ8g",
      "rec2UUQfh04OjdVI3",
      "rec6rG96YBHMQThzW"
    ],
    "knowledge": [
      "reccshxi28Bv59Lp5",
      "rec678c98iQWfsFXX",
      "recEDBqcZuwlasjJA",
      "recTPF1R2PytNX1n2",
      "rec7v5SVUgtSSpXpe"
    ],
    "_id": "recd3GoHu67Un5MCV"
  },
  {
    "slug": "collect_customer_feedback_on_applications",
    "i18n_fr": "collect customer feedback on applications",
    "roles": [
      "recQXoXR5BCWdLyDA",
      "rec2xwN1GQFhJwM3F"
    ],
    "_id": "recM6OAx4A1rYMPzW"
  },
  {
    "slug": "communicate_with_customers",
    "i18n_fr": "communicate with customers",
    "roles": [
      "recQXoXR5BCWdLyDA"
    ],
    "_id": "recnuemonvciwOoOn"
  },
  {
    "slug": "create_data_models",
    "i18n_fr": "create data models",
    "skills": [
      "rec8OZsTp2TeXTRWh",
      "reczdxXM2nZFsPecJ",
      "recD8MIIKPzqwvaZV"
    ],
    "roles": [
      "rec3tS6ps6yNdt8XM"
    ],
    "knowledge": [
      "rec678c98iQWfsFXX",
      "recEDBqcZuwlasjJA"
    ],
    "_id": "reczKDpDApvyj4BqJ"
  },
  {
    "slug": "data_quality_assessment",
    "i18n_fr": "data quality assessment",
    "skills": [
      "rec5a2g099tK2LLu2",
      "rec8OZsTp2TeXTRWh",
      "rec6rG96YBHMQThzW",
      "recRzJtuFIpeGkKj6",
      "recv21KcqVUoRbeWG"
    ],
    "roles": [
      "rec3tS6ps6yNdt8XM",
      "recQXoXR5BCWdLyDA"
    ],
    "knowledge": [
      "recCNiPk4VZnphP1J"
    ],
    "_id": "reci5TT0796QDAnmt"
  },
  {
    "slug": "debug_software",
    "i18n_fr": "debug software",
    "skills": [
      "recGOBAuuS2nXfLI0",
      "recFYpPbMjITdWQSo",
      "recZqEbPF8dPvBDna",
      "recisG5kETS8la7zL",
      "reclm65LkhIwtpMXL"
    ],
    "roles": [
      "recZAf3eYRbuxD2DV",
      "recJmJPdzb2PWy33o",
      "rec2xwN1GQFhJwM3F"
    ],
    "knowledge": [
      "recm183xXmjaPphLT",
      "recIbxElF48MPD78Z",
      "recYiifO3Pruyo1wN"
    ],
    "_id": "rece8nxsVQHOLeIky"
  },
  {
    "slug": "define_data_quality_criteria",
    "i18n_fr": "define data quality criteria",
    "skills": [
      "rec0JLL5Okxt5iVts",
      "rec8OZsTp2TeXTRWh",
      "rec6rG96YBHMQThzW"
    ],
    "knowledge": [
      "recCNiPk4VZnphP1J"
    ],
    "_id": "reclPherlv8HvDolr"
  },
  {
    "slug": "define_database_physical_structure",
    "i18n_fr": "define database physical structure",
    "skills": [
      "recxu1vcyj3AcS13q",
      "recCIRJeMR79RM3d6",
      "recnws7D6JsFyFQCe",
      "recrFfy75I5ANCtSS",
      "recbEs1xfyFk9iwuK",
      "reczxow5FRLNRUbsZ",
      "reco7UX4yqR7Y0FdJ",
      "recJdpHx0J0vboQA3"
    ],
    "roles": [
      "recGjy4kXskTyW0Z1"
    ],
    "knowledge": [
      "recuxwbEUr0a5uSbs",
      "rec678c98iQWfsFXX",
      "recEDBqcZuwlasjJA",
      "recTPF1R2PytNX1n2"
    ],
    "_id": "recw5oesT6KxEPqlP"
  },
  {
    "slug": "define_integration_strategy",
    "i18n_fr": "define integration strategy",
    "skills": [
      "recu7ml1BE16Cv4b0",
      "recwhZYVGpsDG4ega",
      "recIhDAhbRiMbroCV"
    ],
    "roles": [
      "recZAf3eYRbuxD2DV"
    ],
    "knowledge": [
      "recqhbL8hgDH3D4bJ"
    ],
    "_id": "recAXTCXPAEYiAM7u"
  },
  {
    "slug": "define_technical_requirements",
    "i18n_fr": "define technical requirements",
    "roles": [
      "recZAf3eYRbuxD2DV"
    ],
    "knowledge": [
      "recGZW20Z8xRqgIgM",
      "recDTHj0nClcIQRvr",
      "recqhbL8hgDH3D4bJ",
      "reclgK82dFLXwf9Dk",
      "recOkwp8p0pHwltsB",
      "recMdjPIhtMiS4Vw5",
      "recMqv7zTIt4YGvAp"
    ],
    "_id": "recl4BeEAMrPWzivy"
  },
  {
    "slug": "deliver_visual_presentation_of_data",
    "i18n_fr": "deliver visual presentation of data",
    "skills": [
      "recGtJbHSOEXpVXoV",
      "rec5a2g099tK2LLu2",
      "recHmih2kMDn6vw9Q",
      "recRzJtuFIpeGkKj6",
      "recv21KcqVUoRbeWG"
    ],
    "roles": [
      "recQXoXR5BCWdLyDA"
    ],
    "knowledge": [
      "recOkwp8p0pHwltsB",
      "recfnCmt1maRf8n81"
    ],
    "_id": "recn7tjZNcB5hWA3R"
  },
  {
    "slug": "ui_design",
    "i18n_fr": "UI design",
    "skills": [
      "recbV8VzMuYGUAKP4",
      "recd4Wxlzb5RDJHpj",
      "reczX30nq1YWssKAi",
      "rec0lfKWs8rdCo6Q6",
      "recTxrra31W4emm25",
      "recqFJ3I3HmBpJvWt",
      "recABm2oOtCmaAdVH",
      "recgtKHu6BQS6f9Ns",
      "recTpYlQQph00U2nT",
      "recyxhDX4MYAKjGy2",
      "recTM2hxQJ7fDs4tg",
      "rectmyKbq4lcEUjUr",
      "rechiVEW4dMnMqFjf"
    ],
    "roles": [
      "recZAf3eYRbuxD2DV",
      "recJmJPdzb2PWy33o",
      "rec2xwN1GQFhJwM3F"
    ],
    "knowledge": [
      "recqhbL8hgDH3D4bJ",
      "recbbf3XRTUtSteh4",
      "recIXKMieqFFzaNoo"
    ],
    "_id": "rec9kkPLQNk6A3UuC"
  },
  {
    "slug": "design_computer_network",
    "i18n_fr": "design computer network",
    "skills": [
      "rec8N58OVZcKK0zY4",
      "recLnmJEMGdSE6xEo",
      "recu7ml1BE16Cv4b0",
      "recM4enxPhCLTmBu9",
      "recR7jbBVQF8nOot5",
      "recwhwDytIEbxstx2"
    ],
    "knowledge": [
      "recfTUqjHvRReoagd"
    ],
    "_id": "recQp6cNZnOpGNXPr"
  },
  {
    "slug": "design_database_backup_specifications",
    "i18n_fr": "design database backup specifications",
    "skills": [
      "recAia6aBOUvJqqRW",
      "recHWHLMVoUeSD4yf",
      "recsbzlkFYVbK36bf",
      "rectUeRqwUZiNlJb1",
      "recedu9BCQr9M3Tzu"
    ],
    "roles": [
      "recGjy4kXskTyW0Z1"
    ],
    "knowledge": [
      "recIbxElF48MPD78Z",
      "rec678c98iQWfsFXX",
      "recEDBqcZuwlasjJA"
    ],
    "_id": "reciTTtC0oymQpBq5"
  },
  {
    "slug": "design_database_scheme",
    "i18n_fr": "design database scheme",
    "skills": [
      "recFap7taKIF4Waez",
      "recrFfy75I5ANCtSS",
      "recbEs1xfyFk9iwuK",
      "reczxow5FRLNRUbsZ",
      "reco7UX4yqR7Y0FdJ",
      "recJdpHx0J0vboQA3"
    ],
    "roles": [
      "recGjy4kXskTyW0Z1"
    ],
    "knowledge": [
      "rec678c98iQWfsFXX",
      "recEDBqcZuwlasjJA",
      "recSDgb9pwc1FVmzA"
    ],
    "_id": "rec7BwmL6lwKy0wIB"
  },
  {
    "slug": "design_user_interface",
    "i18n_fr": "design user interface",
    "skills": [
      "recXDdWk2JBnA27iQ",
      "recd4Wxlzb5RDJHpj",
      "reczX30nq1YWssKAi",
      "receZy852FFxItsOs",
      "rec0lfKWs8rdCo6Q6",
      "recTxrra31W4emm25"
    ],
    "knowledge": [
      "recIbxElF48MPD78Z",
      "recSDgb9pwc1FVmzA"
    ],
    "_id": "reclmqEtcnM3Kbw1h"
  },
  {
    "slug": "implement_ci/cd_pipelines",
    "i18n_fr": "implement CI/CD pipelines",
    "skills": [
      "recDszx6G5kxpEg5L",
      "recD2cegOmAnaVrfG",
      "recQJNJKDfTggwtNX",
      "rec3rVgqNbDb1SO5j"
    ],
    "roles": [
      "recZAf3eYRbuxD2DV"
    ],
    "knowledge": [
      "recuxwbEUr0a5uSbs",
      "recIbxElF48MPD78Z"
    ],
    "_id": "recL4sF7mQp38frm2"
  },
  {
    "slug": "develop_data_processing_applications",
    "i18n_fr": "develop data processing applications",
    "skills": [
      "rec8OZsTp2TeXTRWh",
      "recHmih2kMDn6vw9Q",
      "recIhDAhbRiMbroCV",
      "recD8MIIKPzqwvaZV",
      "recv21KcqVUoRbeWG"
    ],
    "roles": [
      "rec2xwN1GQFhJwM3F"
    ],
    "knowledge": [
      "recuxwbEUr0a5uSbs",
      "recIbxElF48MPD78Z",
      "rec678c98iQWfsFXX",
      "recEDBqcZuwlasjJA",
      "receRbctW066PXrCk",
      "recmCLnLWrJ8SPqtx",
      "recsu4abka4R9h3Mk",
      "recbbf3XRTUtSteh4"
    ],
    "_id": "recJP0Czoiu38oFyX"
  },
  {
    "slug": "develop_software_prototype",
    "i18n_fr": "develop software prototype",
    "skills": [
      "recd4Wxlzb5RDJHpj",
      "rec0lfKWs8rdCo6Q6",
      "recTxrra31W4emm25"
    ],
    "roles": [
      "rec2xwN1GQFhJwM3F"
    ],
    "knowledge": [
      "recIbxElF48MPD78Z",
      "rec678c98iQWfsFXX",
      "recbbf3XRTUtSteh4",
      "receWvgMtuo3OnQ8V",
      "recMqv7zTIt4YGvAp"
    ],
    "_id": "recO7DzlpuG6sVqxF"
  },
  {
    "slug": "establish_data_processes",
    "i18n_fr": "establish data processes",
    "skills": [
      "rec0JLL5Okxt5iVts",
      "recwhZYVGpsDG4ega",
      "recIhDAhbRiMbroCV",
      "recD8MIIKPzqwvaZV",
      "recCIRJeMR79RM3d6",
      "recnws7D6JsFyFQCe"
    ],
    "roles": [
      "rec3tS6ps6yNdt8XM"
    ],
    "knowledge": [
      "rec678c98iQWfsFXX",
      "recEDBqcZuwlasjJA",
      "recTPF1R2PytNX1n2",
      "recusx5HVngu27OX5",
      "recmCLnLWrJ8SPqtx",
      "rec4oPEighMIkU86G",
      "recjdclWPq09HKvGx"
    ],
    "_id": "recjnWPCBaKAPaoxr"
  },
  {
    "slug": "estimate_duration_of_work",
    "i18n_fr": "estimate duration of work",
    "roles": [
      "recZAf3eYRbuxD2DV"
    ],
    "knowledge": [
      "recWVDi3uDv9A4K96",
      "recZ08vxZtGPVqkZ3"
    ],
    "_id": "recL7PQRj9kup1fx0"
  },
  {
    "slug": "execute_analytical_mathematical_calculations",
    "i18n_fr": "execute analytical mathematical calculations",
    "skills": [
      "recGtJbHSOEXpVXoV",
      "reczddTxBDeCf0L7k",
      "reccetSZBvLlbFTRh",
      "rec8OZsTp2TeXTRWh",
      "rec6rG96YBHMQThzW"
    ],
    "roles": [
      "rec3tS6ps6yNdt8XM",
      "recQXoXR5BCWdLyDA"
    ],
    "knowledge": [
      "recSgW03PW38JRF5w",
      "recjdclWPq09HKvGx"
    ],
    "_id": "reciGRemUY6h8JQuu"
  },
  {
    "slug": "execute_integration_testing",
    "i18n_fr": "execute integration testing",
    "skills": [
      "recd4Wxlzb5RDJHpj",
      "rec0JLL5Okxt5iVts",
      "recCIRJeMR79RM3d6",
      "rec0lfKWs8rdCo6Q6",
      "recTxrra31W4emm25"
    ],
    "knowledge": [
      "recqhbL8hgDH3D4bJ",
      "recIbxElF48MPD78Z"
    ],
    "_id": "recvCo4AX1Sb9FU8F"
  },
  {
    "slug": "execute_software_tests",
    "i18n_fr": "execute software tests",
    "skills": [
      "recd4Wxlzb5RDJHpj",
      "rec4th2r2PE7blYbv",
      "rec0lfKWs8rdCo6Q6",
      "recTxrra31W4emm25"
    ],
    "roles": [
      "recJmJPdzb2PWy33o",
      "rec2xwN1GQFhJwM3F"
    ],
    "knowledge": [
      "recqhbL8hgDH3D4bJ",
      "reclgK82dFLXwf9Dk",
      "reczXLBkeKjFBYo6e",
      "recYiifO3Pruyo1wN"
    ],
    "_id": "recEgCpGeGLFJWTsV"
  },
  {
    "slug": "gather_data_for_forensic_purposes",
    "i18n_fr": "gather data for forensic purposes",
    "skills": [
      "rec5a2g099tK2LLu2",
      "rec8OZsTp2TeXTRWh",
      "rec6rG96YBHMQThzW",
      "recRzJtuFIpeGkKj6",
      "recv21KcqVUoRbeWG"
    ],
    "knowledge": [
      "rec678c98iQWfsFXX",
      "recusx5HVngu27OX5",
      "receRbctW066PXrCk",
      "recmCLnLWrJ8SPqtx"
    ],
    "_id": "rec7gzIMNWNvfM2Vf"
  },
  {
    "slug": "handle_data_samples",
    "i18n_fr": "handle data samples",
    "skills": [
      "reccetSZBvLlbFTRh",
      "recjDYugUQsZSIQ8g",
      "rec2UUQfh04OjdVI3",
      "rec8OZsTp2TeXTRWh",
      "rec6rG96YBHMQThzW"
    ],
    "knowledge": [
      "recSgW03PW38JRF5w",
      "rec678c98iQWfsFXX",
      "rec7v5SVUgtSSpXpe",
      "recmCLnLWrJ8SPqtx",
      "recCNiPk4VZnphP1J"
    ],
    "_id": "reclrFRctY9VnDkxV"
  },
  {
    "slug": "identify_customer_requirements",
    "i18n_fr": "identify customer requirements",
    "roles": [
      "recZAf3eYRbuxD2DV"
    ],
    "knowledge": [
      "reclgK82dFLXwf9Dk",
      "recVhiVTupz4ZNtGx"
    ],
    "_id": "recdRdvztfZRhVv0v"
  },
  {
    "slug": "identify_ict_security_risks",
    "i18n_fr": "identify ICT security risks",
    "roles": [
      "recZAf3eYRbuxD2DV"
    ],
    "knowledge": [
      "recGZW20Z8xRqgIgM",
      "recfTUqjHvRReoagd",
      "reclgK82dFLXwf9Dk"
    ],
    "_id": "rec1gRFxu5PpACT6m"
  },
  {
    "slug": "implement_data_quality_processes",
    "i18n_fr": "implement data quality processes",
    "skills": [
      "rec5a2g099tK2LLu2",
      "rec8OZsTp2TeXTRWh",
      "rec6rG96YBHMQThzW",
      "recRzJtuFIpeGkKj6",
      "recv21KcqVUoRbeWG"
    ],
    "roles": [
      "rec2xwN1GQFhJwM3F"
    ],
    "knowledge": [
      "recCNiPk4VZnphP1J"
    ],
    "_id": "recfHEURDctM5SBJ5"
  },
  {
    "slug": "integrate_data",
    "i18n_fr": "integrate data",
    "skills": [
      "rec0JLL5Okxt5iVts",
      "recwhZYVGpsDG4ega",
      "recIhDAhbRiMbroCV",
      "recCIRJeMR79RM3d6"
    ],
    "roles": [
      "rec3tS6ps6yNdt8XM"
    ],
    "knowledge": [
      "recqhbL8hgDH3D4bJ"
    ],
    "_id": "reczrWbVHTZDLKpcn"
  },
  {
    "slug": "integrate_system_components",
    "i18n_fr": "integrate system components",
    "skills": [
      "recu7ml1BE16Cv4b0",
      "recR7jbBVQF8nOot5"
    ],
    "roles": [
      "recJmJPdzb2PWy33o",
      "rec2xwN1GQFhJwM3F"
    ],
    "knowledge": [
      "recqhbL8hgDH3D4bJ",
      "recIbxElF48MPD78Z",
      "recMQU4mcWb1BcOro",
      "recfPRfSboeU1kJXx",
      "recMqv7zTIt4YGvAp",
      "recIXKMieqFFzaNoo",
      "recGt6ZUFUKLGHLmz",
      "recvUDbxoG43dOsA9"
    ],
    "_id": "recqnDjjIvdfmiqf8"
  },
  {
    "slug": "interpret_data_analysis",
    "i18n_fr": "interpret data analysis",
    "skills": [
      "recGtJbHSOEXpVXoV",
      "reczddTxBDeCf0L7k",
      "recNKlUrhi2lC3zbL",
      "recCWbExpgZ8orDOx",
      "recTlIjLd2vAHQRC3",
      "reczdev3pnh4mnzYS",
      "recHmih2kMDn6vw9Q",
      "recRzJtuFIpeGkKj6",
      "recq4fGFNP7FFoM2K"
    ],
    "roles": [
      "rec3tS6ps6yNdt8XM",
      "recQXoXR5BCWdLyDA"
    ],
    "knowledge": [
      "recVhiVTupz4ZNtGx"
    ],
    "_id": "recrhDzBZbcGBxtGs"
  },
  {
    "slug": "interpret_technical_requirements",
    "i18n_fr": "interpret technical requirements",
    "roles": [
      "recZAf3eYRbuxD2DV",
      "recGjy4kXskTyW0Z1",
      "recJmJPdzb2PWy33o",
      "rec2xwN1GQFhJwM3F"
    ],
    "knowledge": [
      "recGZW20Z8xRqgIgM",
      "recDTHj0nClcIQRvr",
      "recfTUqjHvRReoagd",
      "recqhbL8hgDH3D4bJ",
      "reclgK82dFLXwf9Dk"
    ],
    "_id": "recRwFTe1mtwqLCcq"
  },
  {
    "slug": "maintain_software_systems",
    "i18n_fr": "maintain software systems",
    "skills": [
      "recV0oloTZet7ngTS",
      "recOUQENHMVa2E4S5",
      "recrt8MgDM3493nza",
      "recFW5UB9r8IJW8jY"
    ],
    "roles": [
      "recZAf3eYRbuxD2DV",
      "recJmJPdzb2PWy33o",
      "rec2xwN1GQFhJwM3F"
    ],
    "knowledge": [
      "recGZW20Z8xRqgIgM",
      "recDTHj0nClcIQRvr",
      "recfTUqjHvRReoagd",
      "recBJ8735QF1Mq63i",
      "recf0TgkMWTTSvEpT"
    ],
    "_id": "recSYRbViANMBLi9l"
  },
  {
    "slug": "manage_database_performance",
    "i18n_fr": "manage database performance",
    "skills": [
      "recqMObK3MYN5mwcK",
      "recUhjzuWrlGHR00Y",
      "recfTg9R9lk4pH1nS",
      "recxu1vcyj3AcS13q",
      "recnws7D6JsFyFQCe"
    ],
    "roles": [
      "recGjy4kXskTyW0Z1"
    ],
    "knowledge": [
      "recuxwbEUr0a5uSbs",
      "rec678c98iQWfsFXX",
      "recEDBqcZuwlasjJA",
      "recTPF1R2PytNX1n2",
      "receRbctW066PXrCk",
      "reci6CJlknLRrtnXn"
    ],
    "_id": "rec74bQ6d9DVtOZnB"
  },
  {
    "slug": "manage_database_security",
    "i18n_fr": "manage database security",
    "skills": [
      "recxu1vcyj3AcS13q",
      "recIEvdjdYppBJmou",
      "recnws7D6JsFyFQCe"
    ],
    "roles": [
      "recGjy4kXskTyW0Z1"
    ],
    "knowledge": [
      "recIbxElF48MPD78Z",
      "recUXUc6b3OKnpIea",
      "rec678c98iQWfsFXX",
      "recTPF1R2PytNX1n2"
    ],
    "_id": "rec8d7OVyPYNkPhZS"
  },
  {
    "slug": "manage_big_data",
    "i18n_fr": "manage big data",
    "knowledge": [
      "recVndG0g0lKKjBRA",
      "reccshxi28Bv59Lp5",
      "recjdclWPq09HKvGx"
    ],
    "_id": "recrxIk8BihLH96LS"
  },
  {
    "slug": "manage_data",
    "i18n_fr": "manage data",
    "skills": [
      "reczddTxBDeCf0L7k",
      "recCWbExpgZ8orDOx",
      "recTlIjLd2vAHQRC3",
      "recjDYugUQsZSIQ8g",
      "reczdev3pnh4mnzYS",
      "rec2UUQfh04OjdVI3",
      "recHmih2kMDn6vw9Q",
      "recRzJtuFIpeGkKj6",
      "recGdxuai6WwvGapL"
    ],
    "knowledge": [
      "rec678c98iQWfsFXX",
      "recmCLnLWrJ8SPqtx",
      "recZ08vxZtGPVqkZ3",
      "recVhiVTupz4ZNtGx"
    ],
    "_id": "recXzypfwTcLObGFR"
  },
  {
    "slug": "manage_data_collection_systems",
    "i18n_fr": "manage data collection systems",
    "skills": [
      "recsqBVMrb4Snr9dw",
      "recqMObK3MYN5mwcK",
      "recOVtGPOoeIbUfXZ",
      "recsIwqkQ88suvu5J",
      "recsSSRo8lfyzHoBx",
      "recHJRk6SBCH2Q96U",
      "recUhjzuWrlGHR00Y",
      "recfTg9R9lk4pH1nS",
      "rec0JLL5Okxt5iVts",
      "rec5a2g099tK2LLu2",
      "recRzJtuFIpeGkKj6",
      "recCIRJeMR79RM3d6",
      "recv21KcqVUoRbeWG",
      "recnws7D6JsFyFQCe"
    ],
    "knowledge": [
      "recVndG0g0lKKjBRA",
      "rec678c98iQWfsFXX",
      "recEDBqcZuwlasjJA",
      "recTPF1R2PytNX1n2"
    ],
    "_id": "recoS90Oh3LvLbyNo"
  },
  {
    "slug": "manage_database",
    "i18n_fr": "manage database",
    "skills": [
      "recsqBVMrb4Snr9dw",
      "recqMObK3MYN5mwcK",
      "recOVtGPOoeIbUfXZ",
      "recsIwqkQ88suvu5J",
      "recsSSRo8lfyzHoBx",
      "recHJRk6SBCH2Q96U",
      "recUhjzuWrlGHR00Y",
      "recfTg9R9lk4pH1nS",
      "rec0JLL5Okxt5iVts",
      "rec5a2g099tK2LLu2",
      "recRzJtuFIpeGkKj6",
      "recCIRJeMR79RM3d6",
      "recv21KcqVUoRbeWG",
      "recnws7D6JsFyFQCe"
    ],
    "knowledge": [
      "recVndG0g0lKKjBRA",
      "recMdjPIhtMiS4Vw5",
      "rec678c98iQWfsFXX",
      "recTPF1R2PytNX1n2"
    ],
    "_id": "reczmpx9of9KIBiDU"
  },
  {
    "slug": "design_data_structures",
    "i18n_fr": "design data structures",
    "skills": [
      "recsqBVMrb4Snr9dw",
      "recOVtGPOoeIbUfXZ",
      "recsSSRo8lfyzHoBx",
      "rec0JLL5Okxt5iVts",
      "rec5a2g099tK2LLu2",
      "recRzJtuFIpeGkKj6",
      "recIEvdjdYppBJmou",
      "recsPYgrerXwNZkNC",
      "recsqtdtyQWTSx7Il",
      "recos28HnIYmoDtV3"
    ],
    "knowledge": [
      "recuxwbEUr0a5uSbs",
      "rec4oPEighMIkU86G"
    ],
    "_id": "rec1OwuwM1gV01k6G"
  },
  {
    "slug": "manage_data_classification",
    "i18n_fr": "manage data classification",
    "skills": [
      "rec0JLL5Okxt5iVts",
      "rec5a2g099tK2LLu2",
      "recRzJtuFIpeGkKj6",
      "recIEvdjdYppBJmou",
      "recv21KcqVUoRbeWG"
    ],
    "knowledge": [
      "recmCLnLWrJ8SPqtx",
      "recsu4abka4R9h3Mk",
      "recDyfl8BXBVx4wQL"
    ],
    "_id": "reca0wci8sSQXmx7M"
  },
  {
    "slug": "manage_ict_system_deployment",
    "i18n_fr": "manage ICT system deployment",
    "skills": [
      "recqMObK3MYN5mwcK",
      "recUhjzuWrlGHR00Y",
      "recfTg9R9lk4pH1nS",
      "recR7jbBVQF8nOot5"
    ],
    "knowledge": [
      "recuxwbEUr0a5uSbs",
      "recMdjPIhtMiS4Vw5",
      "reclCyoybGQfopBWE"
    ],
    "_id": "recYq184kkjecTBUC"
  },
  {
    "slug": "work_with_unstructured_data",
    "i18n_fr": "work with unstructured data",
    "skills": [
      "recqMObK3MYN5mwcK",
      "recUhjzuWrlGHR00Y",
      "recD8MIIKPzqwvaZV"
    ],
    "knowledge": [
      "reccshxi28Bv59Lp5",
      "recusx5HVngu27OX5"
    ],
    "_id": "recuPXsyC9NT0Dncx"
  },
  {
    "slug": "database_migration",
    "i18n_fr": "database migration",
    "skills": [
      "recFap7taKIF4Waez",
      "reccGuZvgnfwaWxTc",
      "rec7h8KJIr2DWpLTJ"
    ],
    "roles": [
      "recGjy4kXskTyW0Z1"
    ],
    "knowledge": [
      "recMdjPIhtMiS4Vw5"
    ],
    "_id": "reckInSo5s5292JJg"
  },
  {
    "slug": "monitor_system_performance",
    "i18n_fr": "monitor system performance",
    "knowledge": [
      "recfTUqjHvRReoagd",
      "reczXLBkeKjFBYo6e",
      "recf0TgkMWTTSvEpT"
    ],
    "_id": "recuZim9YBjZhETCu"
  },
  {
    "slug": "normalise_data",
    "i18n_fr": "normalise data",
    "skills": [
      "reccetSZBvLlbFTRh",
      "rec8OZsTp2TeXTRWh",
      "rec6rG96YBHMQThzW",
      "rec0EZLhm62I9V4mJ",
      "recJFdihWNOj47fun"
    ],
    "roles": [
      "rec3tS6ps6yNdt8XM",
      "recQXoXR5BCWdLyDA"
    ],
    "knowledge": [
      "recSgW03PW38JRF5w",
      "recmCLnLWrJ8SPqtx"
    ],
    "_id": "receTkjf9nDeIftj4"
  },
  {
    "slug": "use_software_control_systems",
    "i18n_fr": "use software control systems",
    "skills": [
      "recaR6hpcQjo5p6X9",
      "rechqVNmYf8EqDGny",
      "rechZmPT3rpW9Dkj9",
      "recD2cegOmAnaVrfG",
      "recQJNJKDfTggwtNX",
      "rechpV24ER452lUqc",
      "recEiZjIAaPFQIktG"
    ],
    "roles": [
      "recGjy4kXskTyW0Z1",
      "rec2xwN1GQFhJwM3F"
    ],
    "knowledge": [
      "recVndG0g0lKKjBRA",
      "recMdjPIhtMiS4Vw5"
    ],
    "_id": "recI0yzHcqbV9JILb"
  },
  {
    "slug": "perform_data_cleansing",
    "i18n_fr": "perform data cleansing",
    "skills": [
      "recNKlUrhi2lC3zbL",
      "rec0JLL5Okxt5iVts",
      "rec5a2g099tK2LLu2",
      "rec8OZsTp2TeXTRWh",
      "rec6rG96YBHMQThzW",
      "recRzJtuFIpeGkKj6",
      "recv21KcqVUoRbeWG",
      "recXGdyFbA14hDx5l"
    ],
    "roles": [
      "rec3tS6ps6yNdt8XM",
      "recQXoXR5BCWdLyDA"
    ],
    "knowledge": [
      "recusx5HVngu27OX5",
      "recmCLnLWrJ8SPqtx"
    ],
    "_id": "recXljT6tl3ymvxHS"
  },
  {
    "slug": "perform_data_mining",
    "i18n_fr": "perform data mining",
    "skills": [
      "rec8OZsTp2TeXTRWh",
      "reczdxXM2nZFsPecJ",
      "recD8MIIKPzqwvaZV",
      "recRzJtuFIpeGkKj6",
      "recJFdihWNOj47fun",
      "recXGdyFbA14hDx5l"
    ],
    "roles": [
      "rec3tS6ps6yNdt8XM"
    ],
    "knowledge": [
      "recusx5HVngu27OX5",
      "recmCLnLWrJ8SPqtx",
      "recsu4abka4R9h3Mk"
    ],
    "_id": "recGCSatmE4QunfPi"
  },
  {
    "slug": "perform_software_troubleshooting",
    "i18n_fr": "perform software troubleshooting",
    "skills": [
      "recQjruVmF6TyGCF2",
      "rec3EMggAFhqgPY8Z",
      "rechqVNmYf8EqDGny"
    ],
    "roles": [
      "rec2xwN1GQFhJwM3F"
    ],
    "knowledge": [
      "reczXLBkeKjFBYo6e",
      "recYiifO3Pruyo1wN"
    ],
    "_id": "recuiCHr9ARNxfQvO"
  },
  {
    "slug": "perform_project_management",
    "i18n_fr": "perform project management",
    "skills": [
      "recQZLP0s7PKorB48",
      "rec6YoQ05JFlminLL",
      "rec6ng7sXvEhl20X9",
      "recnVeb4xetU5E92h"
    ],
    "knowledge": [
      "recZ08vxZtGPVqkZ3"
    ],
    "_id": "recBmv2a9FTiKKmqB"
  },
  {
    "slug": "procurement_of_network_equipment",
    "i18n_fr": "procurement of network equipment",
    "knowledge": [
      "recDTHj0nClcIQRvr",
      "recqhbL8hgDH3D4bJ",
      "recfPRfSboeU1kJXx",
      "recIXKMieqFFzaNoo"
    ],
    "_id": "recYZB8Tve1PbuzzG"
  },
  {
    "slug": "provide_ict_support",
    "i18n_fr": "provide ICT support",
    "knowledge": [
      "recGZW20Z8xRqgIgM",
      "recm183xXmjaPphLT",
      "recDTHj0nClcIQRvr",
      "recfTUqjHvRReoagd",
      "recPmKxRA8fDPl0au",
      "reclgK82dFLXwf9Dk",
      "recIbxElF48MPD78Z"
    ],
    "_id": "recYRg37F5rrX680f"
  },
  {
    "slug": "provide_technical_documentation",
    "i18n_fr": "provide technical documentation",
    "roles": [
      "recQXoXR5BCWdLyDA",
      "recZAf3eYRbuxD2DV",
      "recJmJPdzb2PWy33o",
      "rec2xwN1GQFhJwM3F"
    ],
    "knowledge": [
      "recfnCmt1maRf8n81"
    ],
    "_id": "rec0LFCXh3glkLIms"
  },
  {
    "slug": "report_analysis_results",
    "i18n_fr": "report analysis results",
    "skills": [
      "rec5a2g099tK2LLu2",
      "recv21KcqVUoRbeWG",
      "rec6aMBpKgmZSxVF4"
    ],
    "roles": [
      "rec3tS6ps6yNdt8XM",
      "recQXoXR5BCWdLyDA"
    ],
    "knowledge": [
      "recOkwp8p0pHwltsB",
      "recVhiVTupz4ZNtGx"
    ],
    "_id": "recQqe8MmkzujAVEn"
  },
  {
    "slug": "solve_ict_system_problems",
    "i18n_fr": "solve ICT system problems",
    "knowledge": [
      "recm183xXmjaPphLT",
      "recPmKxRA8fDPl0au",
      "recIbxElF48MPD78Z",
      "recYiifO3Pruyo1wN"
    ],
    "_id": "recLLff6ryF9JafOn"
  },
  {
    "slug": "support_ict_system_users",
    "i18n_fr": "support ICT system users",
    "roles": [
      "rec2xwN1GQFhJwM3F"
    ],
    "knowledge": [
      "reclgK82dFLXwf9Dk",
      "recIbxElF48MPD78Z"
    ],
    "_id": "rec2f7gCjGqZMFQm0"
  },
  {
    "slug": "test_database_queries",
    "i18n_fr": "test database queries",
    "skills": [
      "recq4fGFNP7FFoM2K"
    ],
    "roles": [
      "recJmJPdzb2PWy33o",
      "rec2xwN1GQFhJwM3F"
    ],
    "knowledge": [
      "recGZW20Z8xRqgIgM",
      "reclgK82dFLXwf9Dk",
      "reczXLBkeKjFBYo6e"
    ],
    "_id": "recy6mJPcYxBqtEKX"
  },
  {
    "slug": "use_concurrent_programming",
    "i18n_fr": "use concurrent programming",
    "skills": [
      "recbV8VzMuYGUAKP4",
      "recXDdWk2JBnA27iQ",
      "reczX30nq1YWssKAi",
      "rec8OZsTp2TeXTRWh",
      "rec3Pz3p2mbRkjGRg",
      "recqQfdUb1ixhAyOQ"
    ],
    "roles": [
      "recJmJPdzb2PWy33o",
      "rec2xwN1GQFhJwM3F",
      "recZAf3eYRbuxD2DV"
    ],
    "knowledge": [
      "recuxwbEUr0a5uSbs",
      "recIbxElF48MPD78Z",
      "recbbf3XRTUtSteh4"
    ],
    "_id": "rec9W6amc0X0xwO0S"
  },
  {
    "slug": "use_functional_programming",
    "i18n_fr": "use functional programming",
    "skills": [
      "recrt8MgDM3493nza",
      "reczX30nq1YWssKAi",
      "recSMuzLyLMAF3tOx",
      "rec8OZsTp2TeXTRWh",
      "recbFCdOxeP8IrFJq"
    ],
    "roles": [
      "recJmJPdzb2PWy33o",
      "rec2xwN1GQFhJwM3F"
    ],
    "knowledge": [
      "recIbxElF48MPD78Z",
      "recbbf3XRTUtSteh4",
      "recQh22bAwKZweEvs"
    ],
    "_id": "receFvIZIE5mjeVt8"
  },
  {
    "slug": "use_structured_programming",
    "i18n_fr": "use structured programming",
    "skills": [
      "rec7sHWmVmJSP5bOA",
      "recM4enxPhCLTmBu9",
      "rec8OZsTp2TeXTRWh",
      "recU2xbwmUUfq5EXm",
      "recR0CyKCaF6UTtys",
      "recDUUNY7pdKnUyYW",
      "recvV2IY0UZobY6L7"
    ],
    "roles": [
      "recJmJPdzb2PWy33o",
      "rec2xwN1GQFhJwM3F"
    ],
    "knowledge": [
      "recIbxElF48MPD78Z",
      "recbbf3XRTUtSteh4"
    ],
    "_id": "reccpJl4kljvhFH26"
  },
  {
    "slug": "use_object_oriented_programming",
    "i18n_fr": "use object-oriented programming",
    "skills": [
      "recbV8VzMuYGUAKP4",
      "recXDdWk2JBnA27iQ",
      "reczX30nq1YWssKAi",
      "recSMuzLyLMAF3tOx",
      "rec8OZsTp2TeXTRWh",
      "recbFCdOxeP8IrFJq"
    ],
    "roles": [
      "recJmJPdzb2PWy33o",
      "rec2xwN1GQFhJwM3F"
    ],
    "knowledge": [
      "recIbxElF48MPD78Z",
      "recbbf3XRTUtSteh4"
    ],
    "_id": "recxZ0ciHmxroa0sE"
  },
  {
    "slug": "use_scripting_programming",
    "i18n_fr": "use scripting programming",
    "skills": [
      "recU2xbwmUUfq5EXm",
      "recR0CyKCaF6UTtys",
      "recDUUNY7pdKnUyYW"
    ],
    "roles": [
      "rec3tS6ps6yNdt8XM",
      "recJmJPdzb2PWy33o",
      "rec2xwN1GQFhJwM3F"
    ],
    "knowledge": [
      "recIbxElF48MPD78Z",
      "recbbf3XRTUtSteh4"
    ],
    "_id": "rec7qmZpxxVriS83v"
  },
  {
    "slug": "use_software_design_patterns",
    "i18n_fr": "use software design patterns",
    "skills": [
      "recQ9ga0XFilWXVwx",
      "rec8pNy2NPSqh1zFa"
    ],
    "roles": [
      "recZAf3eYRbuxD2DV",
      "recJmJPdzb2PWy33o",
      "rec2xwN1GQFhJwM3F"
    ],
    "knowledge": [
      "recIbxElF48MPD78Z"
    ],
    "_id": "recj59I86qlX3mFSZ"
  },
  {
    "slug": "use_technical_drawing_software",
    "i18n_fr": "use technical drawing software",
    "roles": [
      "recZAf3eYRbuxD2DV",
      "recJmJPdzb2PWy33o",
      "rec2xwN1GQFhJwM3F"
    ],
    "knowledge": [
      "recSDgb9pwc1FVmzA"
    ],
    "_id": "reczmQFT5fBmz2taI"
  },
  {
    "slug": "utilise_computer_aided_software_engineering_tools",
    "i18n_fr": "utilise computer-aided software engineering tools",
    "skills": [
      "recQZLP0s7PKorB48",
      "recd4Wxlzb5RDJHpj",
      "rec0lfKWs8rdCo6Q6",
      "recTxrra31W4emm25"
    ],
    "roles": [
      "recZAf3eYRbuxD2DV"
    ],
    "knowledge": [
      "recMQU4mcWb1BcOro",
      "recGt6ZUFUKLGHLmz"
    ],
    "_id": "rec3lk8cU6hPvbCUC"
  },
  {
    "slug": "utilise_machine_learning",
    "i18n_fr": "utilise machine learning",
    "skills": [
      "recpfMg61Q3XVIPll",
      "recwA7GEZYXgDilV6",
      "rec8OZsTp2TeXTRWh",
      "reczdxXM2nZFsPecJ",
      "recD8MIIKPzqwvaZV"
    ],
    "roles": [
      "rec3tS6ps6yNdt8XM"
    ],
    "knowledge": [
      "recIbxElF48MPD78Z",
      "recmCLnLWrJ8SPqtx",
      "recsu4abka4R9h3Mk"
    ],
    "_id": "recinnuSKAv8Cnm8Q"
  },
  {
    "slug": "verify_formal_ict_specifications",
    "i18n_fr": "verify formal ICT specifications",
    "roles": [
      "recZAf3eYRbuxD2DV"
    ],
    "knowledge": [
      "recDTHj0nClcIQRvr",
      "recqhbL8hgDH3D4bJ",
      "recIbxElF48MPD78Z"
    ],
    "_id": "recJFNZr4xJr0YXD2"
  },
  {
    "slug": "write_database_documentation",
    "i18n_fr": "write database documentation",
    "roles": [
      "recZAf3eYRbuxD2DV",
      "recGjy4kXskTyW0Z1"
    ],
    "knowledge": [
      "recOkwp8p0pHwltsB",
      "recfnCmt1maRf8n81",
      "rec678c98iQWfsFXX"
    ],
    "_id": "recl1U1WjQP3uTDvJ"
  },
  {
    "slug": "java_programming",
    "i18n_fr": "Java programming",
    "skills": [
      "recd4Wxlzb5RDJHpj",
      "reczX30nq1YWssKAi",
      "recOUQENHMVa2E4S5",
      "recHM3tqCuGaOvoRm"
    ],
    "roles": [
      "recJmJPdzb2PWy33o"
    ],
    "knowledge": [
      "recyMWCDsBgQNHK9i"
    ],
    "_id": "recrSgWc9kJ9H20Iv"
  },
  {
    "slug": "build_and_package_java_projects",
    "i18n_fr": "build and package Java projects",
    "skills": [
      "recg0daRtcTlXCiMe",
      "rec6YoQ05JFlminLL",
      "recbu9t6j2HN2StU3",
      "recwbzECLIM4E8CQ6"
    ],
    "roles": [
      "recJmJPdzb2PWy33o"
    ],
    "_id": "rec23A4sYpb6azRct"
  },
  {
    "slug": "apply_software_design",
    "i18n_fr": "apply software design",
    "skills": [
      "recsEe7kQ41npXawL",
      "recRhzmBGevT954dW",
      "recnVDCtWjtDan11H",
      "recUEjsQEqQVL4aQd"
    ],
    "roles": [
      "recJmJPdzb2PWy33o",
      "rec2xwN1GQFhJwM3F"
    ],
    "_id": "recJCVOTFfc2EjdNS"
  },
  {
    "slug": "design_and_implement_java_rest_api",
    "i18n_fr": "design and implement Java Rest api",
    "skills": [
      "rec9YD1Rqc7am0wr7",
      "recAqk5cjuwemQMl3"
    ],
    "roles": [
      "recJmJPdzb2PWy33o"
    ],
    "_id": "rec3NjZeCim6rgoY3"
  },
  {
    "slug": "use_java_ecosystem",
    "i18n_fr": "use Java ecosystem",
    "skills": [
      "recySiHanmUMHEVlx",
      "recFffyPmvX5GL85V",
      "recoyyOmS9aD4E1vj",
      "recUxJrrNxtDJMc6j"
    ],
    "roles": [
      "recJmJPdzb2PWy33o"
    ],
    "knowledge": [
      "recogfPGuqFubcBEJ"
    ],
    "_id": "reczaQMnOeLu1ZtiF"
  },
  {
    "slug": "serialisation",
    "i18n_fr": "serialisation",
    "skills": [
      "recr5qHaOU3syIDNB",
      "reck5Lxl81QeJpffT"
    ],
    "roles": [
      "recJmJPdzb2PWy33o",
      "rec2xwN1GQFhJwM3F"
    ],
    "_id": "recYPDeXZxVC0pm0H"
  },
  {
    "slug": "apply_test_driven_development_in_java",
    "i18n_fr": "apply test driven development in Java",
    "skills": [
      "reclyIVn2usOuIc6u"
    ],
    "roles": [
      "recJmJPdzb2PWy33o"
    ],
    "_id": "recmJroKIf6EaWT4j"
  },
  {
    "slug": "perform_java_debugging",
    "i18n_fr": "perform Java debugging",
    "skills": [
      "recrqaw5pHWRtwIqO"
    ],
    "roles": [
      "recJmJPdzb2PWy33o"
    ],
    "_id": "recgyaouQIQbxZup3"
  },
  {
    "slug": "use_java_frameworks",
    "i18n_fr": "use Java frameworks",
    "skills": [
      "recgg7Hk3SQl0hiQB"
    ],
    "roles": [
      "recJmJPdzb2PWy33o"
    ],
    "_id": "recCieS1adNjluHIR"
  },
  {
    "slug": "python_programming",
    "i18n_fr": "Python programming",
    "skills": [
      "rec8OZsTp2TeXTRWh",
      "recOUQENHMVa2E4S5",
      "recHM3tqCuGaOvoRm",
      "recrt8MgDM3493nza",
      "recFW5UB9r8IJW8jY",
      "rec4e8PM6r4eBzJM9"
    ],
    "roles": [
      "rec2xwN1GQFhJwM3F"
    ],
    "knowledge": [
      "recyMWCDsBgQNHK9i",
      "recQh22bAwKZweEvs",
      "recgaCOpAyt4jJG37"
    ],
    "_id": "recj60hMGvgCYQbZh"
  },
  {
    "slug": "package_python_projects",
    "i18n_fr": "package Python projects",
    "roles": [
      "rec2xwN1GQFhJwM3F"
    ],
    "_id": "receYERyWVbVJFgLo"
  },
  {
    "slug": "design_and_implement_python_rest_api",
    "i18n_fr": "design and implement Python Rest api",
    "skills": [
      "recyofyeZYf7A8hos",
      "recYnsB6k7uHLiJYa"
    ],
    "roles": [
      "rec2xwN1GQFhJwM3F"
    ],
    "_id": "recuvv1H36ugLc5G1"
  },
  {
    "slug": "apply_test_driven_development_in_python",
    "i18n_fr": "apply test driven development in Python",
    "skills": [
      "recjM2oW9Nnsnp8rx"
    ],
    "roles": [
      "rec2xwN1GQFhJwM3F"
    ],
    "_id": "recDdu5xmH10S8ArN"
  },
  {
    "slug": "perform_python_debugging",
    "i18n_fr": "perform Python debugging",
    "skills": [
      "recGOBAuuS2nXfLI0"
    ],
    "_id": "recZzm0aI3ip44nSV"
  },
  {
    "slug": "use_java_frameworks",
    "i18n_fr": "use Java frameworks",
    "skills": [
      "recgg7Hk3SQl0hiQB"
    ],
    "roles": [
      "recJmJPdzb2PWy33o"
    ],
    "_id": "recl6jv2okR00F9ZJ"
  },
  {
    "slug": "work_with_non_relational_database_systems",
    "i18n_fr": "work with non-relational database systems",
    "skills": [
      "recqMObK3MYN5mwcK",
      "recOdCreCZbtU73QL",
      "recUhjzuWrlGHR00Y",
      "recfTg9R9lk4pH1nS",
      "recD8MIIKPzqwvaZV",
      "recnws7D6JsFyFQCe",
      "recbFCdOxeP8IrFJq",
      "reciLIUEQ51J9QyIR"
    ],
    "_id": "recjFqvpEirvyjI5G"
  },
  {
    "slug": "use_frameworks",
    "i18n_fr": "use frameworks",
    "skills": [
      "recBjG0vcBtfB2PaB",
      "rec9fVsyZxWwF2IKL",
      "recaJs96ysFl2xzpm",
      "recoMqiFfqogujE57"
    ],
    "_id": "recrqMlhKmtxK9XuA"
  },
  {
    "slug": "report_analysis_results",
    "i18n_fr": "report analysis results",
    "skills": [
      "rec6aMBpKgmZSxVF4"
    ],
    "_id": "rec3euzwDul2K3AYP"
  },
  {
    "slug": "use_parallel_programming",
    "i18n_fr": "use parallel programming",
    "skills": [
      "recOdCreCZbtU73QL",
      "recD8MIIKPzqwvaZV",
      "recdsaOdvaAcmNNbg"
    ],
    "_id": "recl95MWv7qUvcfV5"
  },
  {
    "slug": "compile_projects",
    "i18n_fr": "compile projects",
    "skills": [
      "rec1m174gN77Sgmqs"
    ],
    "roles": [
      "recJmJPdzb2PWy33o"
    ],
    "_id": "rect8WK1XqiZDjePz"
  },
  {
    "slug": "class_based_object_oriented_modelling",
    "i18n_fr": "class-based object-oriented modelling",
    "skills": [
      "recbV8VzMuYGUAKP4",
      "recXDdWk2JBnA27iQ",
      "reczX30nq1YWssKAi"
    ],
    "roles": [
      "recZAf3eYRbuxD2DV",
      "recJmJPdzb2PWy33o"
    ],
    "_id": "rec2F9rihT3VAelPM"
  },
  {
    "slug": "prototype_based_object_oriented_modelling",
    "i18n_fr": "prototype-based object-oriented modelling",
    "skills": [
      "recjzwzgm5dAIIzpD",
      "recwsso7iGQaexMFP",
      "recSMuzLyLMAF3tOx",
      "rec8OZsTp2TeXTRWh",
      "recbFCdOxeP8IrFJq"
    ],
    "roles": [
      "recZAf3eYRbuxD2DV",
      "rec2xwN1GQFhJwM3F"
    ],
    "_id": "recpymrM3euHuo8eY"
  },
  {
    "slug": "develop_infrastructure_by_code",
    "i18n_fr": "develop infrastructure by code",
    "skills": [
      "rec7jyxS52xN0IR8d",
      "recQwfJpJES4gWkW3",
      "recYwh9UHjLPouGA1"
    ],
    "roles": [
      "recZAf3eYRbuxD2DV"
    ],
    "_id": "recTLkVqV6WQefaMi"
  },
  {
    "slug": "perform_unit_tests",
    "i18n_fr": "perform unit tests",
    "skills": [
      "reclyIVn2usOuIc6u",
      "recjM2oW9Nnsnp8rx",
      "recsed5mx5pXbF5zk",
      "recchjIAqf85h8P8o",
      "recGPjrSwidTsdlbb"
    ],
    "roles": [
      "recZAf3eYRbuxD2DV"
    ],
    "_id": "rec0rQ2MyOoJoxX4K"
  }
]