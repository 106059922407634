export const regex = (str, loose) => {
  if (str instanceof RegExp) return { keys: false, pattern: str }
  var c,
    o,
    tmp,
    ext,
    keys = [],
    pattern = '',
    arr = str.split('/')
  arr[0] || arr.shift()

  while ((tmp = arr.shift())) {
    c = tmp[0]
    if (c === '*') {
      keys.push('wild')
      pattern += '/(.*)'
    } else if (c === ':') {
      o = tmp.indexOf('?', 1)
      ext = tmp.indexOf('.', 1)
      keys.push(tmp.substring(1, !!~o ? o : !!~ext ? ext : tmp.length))
      pattern += !!~o && !~ext ? '(?:/([^/]+?))?' : '/([^/]+?)'
      if (!!~ext) pattern += (!!~o ? '?' : '') + '\\' + tmp.substring(ext)
    } else {
      pattern += '/' + tmp
    }
  }

  return {
    keys: keys,
    pattern: new RegExp('^' + pattern + (loose ? '(?=$|/)' : '/?$'), 'i'),
  }
}

export const exec = (path, result) => {
  const { pattern, keys } = result
  let i = 0,
    out = {}
  let matches = pattern.exec(path)
  console.log(path, pattern)
  if (!matches) {
    return
  }
  while (i < keys.length) {
    out[keys[i]] = matches[++i] || null
  }
  return out
}
