import React, { Component } from "react";
import { VSpacer } from "@iml/design-layout";
import { Styled, CardStyled, Listing } from "./styled";

const Card = props => {
  const { title } = props;
  return (
    <CardStyled>
      <h1>{title}</h1>
    </CardStyled>
  );
};

const StillHaveQuestionSection = props => {
  return (
    <Styled>
      <h1>Still have questions about how to use SAAKOR?</h1>
      <VSpacer size={32} />
      <button>Explore our FAQs</button>
    </Styled>
  );
};

export default StillHaveQuestionSection;
