import React from "react";
import Card, { Section } from "@skc/block-skillscloud/card";
import {
  listTasksOfSkill,
  asLabel,
  asDescription
} from "@skc/domain-skillscloud/backoffice/utils";

const SkillCard = props => {
  const { skill } = props;
  if (!skill) {
    return "(search for a skill)";
  }
  const titlecase = "titlecase";

  const skillName = asLabel(titlecase)(skill);
  const skillDesc = asDescription(titlecase)(skill);
  const taskList = listTasksOfSkill(skill, asLabel).join(",");
  const associatedRoles = "associatedRoles";

  return (
    <Card title={"skill: " + skillName}>
      <Section title="Description">{skillDesc}</Section>
      <Section title="Associated Roles">{associatedRoles}</Section>
      <Section title="Associated Tasks">{taskList}</Section>
    </Card>
  );
};

export default SkillCard;
