import React from "react";
import SinglePageApp from "@iml/block-app/single-page-app/";

import Helmet from "./SiteHelmet";
import Masthead from "@skc/block-skillscloud/skillscloud-masthead/";
import Routes from "./SiteRoutes";
import Footer from "@skc/block-skillscloud/skillscloud-footer/";

const Site = props => {
  const branding = undefined;

  return (
    <SinglePageApp
      {...{
        helmet: <Helmet />,
        masthead: <Masthead branding={branding} />,
        footer: <Footer />
      }}
    >
      <Routes />
    </SinglePageApp>
  );
};

export default Site;
