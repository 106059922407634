import React from "react";
import { Styled } from "./styled";

const LanguagePicker = props => {
  return (
    <Styled>
      <span data-lg="en-uk">en</span>&nbsp;|&nbsp;
      <span data-lg="fr-be">fr</span>&nbsp;|&nbsp;
      <span data-lg="nl-be">nl</span>
    </Styled>
  );
};

export default LanguagePicker;
