import styled from "styled-components";

export const Site = styled.div`
  position: relative;
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  border-sizing: border-box;
  min-width: 1240px;
`;

export const Body = styled.div`
  margin: 0 8px;
`;
