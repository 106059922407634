import React from "react";
import { Site, Body } from "./styled";
import withHelmet from "./withHelmet";

export const propTypes = {
  /* helmet: PropTypes.number */
};

const SinglePageApp = props => {
  const { children, masthead /*, cookienotice*/, footer } = props;
  return (
    <Site>
      {masthead}
      <Body>{children}</Body>
      {footer}
    </Site>
  );
};
SinglePageApp.propTypes = propTypes;

export default withHelmet(SinglePageApp);

// https://medium.com/mtholla/build-a-framework-free-dashboard-using-css-grid-and-flexbox-53d81c4aee68
