import React, { Component } from "react";
import { Styled, Columns, CardStyled } from "./styled";
import { VSpacer } from "@iml/design-layout";
import building from "./etc/office-building.svg";
import book from "./etc/open-book.svg";
import workers from "./etc/workers.svg";

const Card = props => {
  const { icon, title, children } = props;
  return (
    <CardStyled>
      <img src={icon} height={96} />
      <h1>{title}</h1>
      <div>{children}</div>
    </CardStyled>
  );
};

const BeneficiariesSection = props => {
  const { button } = props;
  return (
    <Styled>
      <Columns>
        <Card icon={building} title="For employers">
          Use SAAKOR to identify skills requirements behind each occupation and
          create effective job postings that will attract the right candidates.
        </Card>
        <Card icon={book} title="For higher education">
          Translate your curriculum into the language of the labor market and
          engage students with personalized learning pathways.
        </Card>
        <Card icon={workers} title="For talents">
          Discover existing occupations on the labour market and find training
          resources to support your learning & development journey.
        </Card>
      </Columns>
      <VSpacer size={32} />
      {button}
    </Styled>
  );
};

export default BeneficiariesSection;
