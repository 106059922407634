import React from "react";
import { CardTitle, SectionTitle, SectionContent } from "./styled";
import { VBox } from "@iml/design-layout";
import styled from "styled-components";

const Styled = styled(VBox)`
  top: 4px;
  box-sizing: border-box;
  border: 8px solid;
  border-color: black;
  border-radius: 1rem;
  opacity: 1;
`;

export const Title = CardTitle;

export const Section = props => {
  const { title, children } = props;
  return (
    <VBox>
      <SectionTitle>{title}</SectionTitle>
      <SectionContent>{children}</SectionContent>
    </VBox>
  );
};

export const Card = props => {
  const { title, children } = props;
  return (
    <Styled>
      <Title>{title}</Title>
      {children}
    </Styled>
  );
};

export default Card;
