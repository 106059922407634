import styled from "styled-components";

export const Styled = styled.div`
  width: 100%;
  margin: 0;
`;

export const SpreadOut = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
